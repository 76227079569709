import Echo from "laravel-echo";

Vue.component('page-intermediate', {
    data() {
        return {
            reservation: apr.reservation
        }
    },

    created() {
        this.initPusher();
    },

    mounted() {
        window.Echo.channel('pnf-reservation-updated')
            .listen('.BroadcastParkNFlyReservationUpdated', (e) => {
                console.log(e);
                this.handleEvent(e)
            });

        window.Echo.channel('tps-reservation-updated')
            .listen('.BroadcastTpsReservationUpdated', (e) => {
                console.log(e);
                this.handleEvent(e)
            });

        window.Echo.channel('hilton-reservation-updated')
            .listen('.BroadcastHiltonReservationUpdated', (e) => {
                console.log(e);
                this.handleEvent(e)
            });

        window.Echo.channel('sg-reservation-updated')
            .listen('.BroadcastSpaceGeniusReservationUpdated', (e) => {
                console.log(e);
                this.handleEvent(e)
            });

        window.Echo.channel('p2f-reservation-updated')
            .listen('.BroadcastPark2FlyReservationUpdated', (e) => {
                console.log(e);
                this.handleEvent(e)
            });

        window.Echo.channel('fhr-reservation-updated')
            .listen('.BroadcastFhrReservationUpdated', (e) => {
                console.log(e);
                this.handleEvent(e)
            });

        setTimeout(function(){
            location.reload();
        },5000);
    },

    methods: {
        initPusher: function () {
            window.Echo = new Echo({
                broadcaster: 'pusher',
                key: window.apr.pusher_key,
                cluster: window.apr.pusher_cluster,
                encrypted: true
            });
        },

        handleEvent(result) {
            if (this.reservation.reservation_id == result.id && this.reservation.token == result.token) {
                window.location.href = result.redirect_url
            }
        }
    }
});
