<template>

    <div :id="target" class="modal fade" tabindex="-1" role="dialog" :aria-labelledby="label"
         aria-hidden="true">
        <div class="modal-dialog" :class="size">
            <div class="modal-content">

                <slot name="modal-header">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal"><span>&times;</span></button>
                        <h4 class="modal-title">
                            <slot name="title">{{title}}</slot>
                        </h4>
                    </div>
                </slot>

                <slot name="modal-body">
                    <div class="modal-body"></div>
                </slot>

                <slot name="modal-footer">
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">{{ cancelText }}</button>
                        <button type="button" class="btn btn-primary" @click="callback">{{ okText }}</button>
                    </div>
                </slot>
            </div>
        </div>
    </div>

</template>

<script>

    export default {

        props: {
            target: {
                type: String,
                default: 'MyModal'
            },
            label: {
                type: String,
                default: 'myModalLabel'
            },
            okText: {
                type: String,
                default: 'Save changes'
            },
            cancelText: {
                type: String,
                default: 'Close'
            },
            title: {
                type: String,
                default: ''
            },
            size: {
                type: String,
                default: ''
            },
            width: {
                default: null
            },
            callback: {
                type: Function,
                default() {
                }
            },
            effect: {
                type: String,
                default: null
            }
        }

    }

</script>
