import Heading from '../components/heading'

Vue.component('us-page-home', {

    mixins: [Heading],

    data() {
        return {
            heading: null,
            to: null
        }
    },

    mounted() {
        this.heading = this.getQueryVariable('utm_term')
    },
});
