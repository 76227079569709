Vue.component('page-help', {

    data() {
        return {}
    },

    mounted() {
        this.hash()

        window.onhashchange = this.setActiveTab

        let self = this
        this.$root.$on('session-received', function (data) {
            let login = data.login

            if (login.role == "customer") {
                self.is_customer = true;
            }

            self.redirectCustomerIfNeeded()
        })
    },

    computed: {},

    watch: {},

    methods: {
        hash() {
            // on page load (check what in hash and make it active)
            this.setActiveTab()

            $('.nav-pills a[data-toggle="pill"]').on('shown.bs.tab', function (e) {
                window.location.hash = e.target.hash.substr(1);
                return false;
            });
        },

        setActiveTab() {
            this.redirectCustomerIfNeeded()

            if (window.location.hash !== '') {
                $('.nav-pills a[href="' + window.location.hash + '"]').tab('show');
            } else {
                $('.nav-pills a:first').tab('show')
            }
        },

        redirectCustomerIfNeeded() {
            if (this.is_customer) {
                if (window.location.hash == "#receipt" || window.location.hash == "#cancellations") {
                    window.location.href = '/customer';
                }
            }
        }
    }
});
