Vue.component('page-help-contact', {

    components: {},

    data() {
        return {
            firstname: null,
            lastname: null,
            email: null,
            category: '',
            reservation_id: null,
            comments: null,

            successMessage: null,
            errorMessage: null,
        }
    },

    mounted() {
        this.initForm()
    },

    computed: {},

    watch: {},

    methods: {
        initForm() {
            let self = this
            let requesting = false;
            let $form = $(this.$el).find("form")

            $form.parsley().on("form:submit", function () {
                $form.find("input").each(function () {
                    $(this).parsley().removeError('custom-error');
                });
                return false;

            }).on('form:success', function (formInstance) {

                if (requesting) {
                    return false;
                }
                requesting = true;
                self.successMessage = null
                self.errorMessage = null

                self.$http.post('/help/contact', self.getParams()).then(response => {
                    self.successMessage = response.data.message

                    $('html, body').animate({scrollTop: 0}, 500);

                }, response => {
                    let errors = response.body

                    for (var errorName in errors) {
                        if (errorName == 'errors') {
                            self.errorMessage = self.filters.formatMessage(errors[errorName])
                        }

                        let $field = $form.find(`input[name="${errorName}"]`);

                        if ($field.length) {
                            $field.parsley().addError('custom-error', {message: errors[errorName]});
                        }
                    }

                    requesting = false;
                });

                return false;
            });
        },

        getParams() {
            return {
                firstname: this.firstname,
                lastname: this.lastname,
                email: this.email,
                category: this.category,
                reservation_id: this.reservation_id,
                comments: this.comments,
            };
        }
    }
})
