<template>
    <section v-if="lots.length > 3" class="closest-lots">
        <div class="closest-lots__container container">
            <h2 class="closest-lots__title section-title">
                <template v-if="title">{{ title }}</template>
                <template v-else>Parking closest to the {{ code }} Airport</template>
            </h2>
            <div v-if="subtitle" class="closest-lots__subtitle section-subtitle" v-html="subtitle"></div>
            <ul class="closest-lots__list">
                <li
                    v-for="(lot, index) in lots"
                    :key="index"
                    class="closest-lots__item"
                    :class="{ 'is-visible': isVisible(index) }"
                >
                    <div class="l-card card">
                        <img v-lazy="lot.logo" class="l-card__img" />
                        <div class="l-card__text">
                            <a :href="lot.slug" class="l-card__link">
                                <h4 class="l-card__title">{{ lot.name }}</h4>
                            </a>
                            <div v-if="lot.ratings.rating" class="l-card__rating">
                                <star-rating
                                    :rating="parseFloat(lot.ratings.rating).toFixed(1)"
                                    :reviews="lot.ratings.comment_count"
                                ></star-rating>
                            </div>
                            <div class="l-card__bottom">
                                <div class="l-card__details">
                                    <div class="l-card__info">
                                        <i class="fa fa-plane"></i>
                                        {{ parseFloat(lot.distance, 1) }} miles from {{ code }}
                                    </div>
                                    <div
                                        v-if="lot.shuttle_option"
                                        class="l-card__info"
                                        :class="
                                            getShuttleIconClass(lot.shuttle_option) === 'fa-map-signs'
                                                ? 'multi-lines'
                                                : ''
                                        "
                                    >
                                        <i class="fa" :class="getShuttleIconClass(lot.shuttle_option)"></i>
                                        {{ lot.shuttle_option }}
                                    </div>
                                    <div class="apr-card__info">
                                        <i class="fa fa-car"></i>
                                        <div
                                            >Parking Options:
                                            <span v-for="(option, index) in lot.options" :key="option">
                                                {{ option }}{{ index === lot.options.length - 1 ? '' : ',' }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="l-card__btn">
                                    <a :href="lot.slug" class="btn-brand-primary">View Rates</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="closest-lots__btn">
                <button
                    v-if="showBtn"
                    class="show-more-btn"
                    :class="btnOptions.btnClass"
                    @click="changeVisibleLotsCount"
                >
                    <span class="show-more-btn__up"><i class="fa fa-chevron-up"></i></span>
                    <span class="show-more-btn__text">{{ btnOptions.btnText }}</span>
                    <span class="show-more-btn__down"><i class="fa fa-chevron-down"></i></span>
                </button>
            </div>
        </div>
    </section>
</template>

<script>
import StarRating from '../stars.vue';

export default {
    props: ['title', 'lots', 'subtitle', 'defaultTitle', 'code'],

    components: { StarRating },

    data() {
        return {
            visibleLotsCount: 4,
        };
    },
    computed: {
        showBtn() {
            return this.lots.length > 4;
        },
        allLotsAreVisible() {
            return this.visibleLotsCount >= this.lots.length;
        },
        btnOptions() {
            return this.allLotsAreVisible
                ? {
                      btnClass: 'is-hide',
                      btnText: 'Hide products',
                  }
                : {
                      btnClass: '',
                      btnText: 'Show more products',
                  };
        },
    },
    methods: {
        changeVisibleLotsCount() {
            // If all lots are visible,
            // app sets visible lots count to initials state - only 4 stays visible,
            // in another case app shows +6 new lots
            this.visibleLotsCount = this.allLotsAreVisible ? 4 : (this.visibleLotsCount += 6);
        },
        isVisible(index) {
            return index < this.visibleLotsCount;
        },
        // CHANGE ICONS!!!!
        getShuttleIconClass($shuttleOption) {
            if ($shuttleOption === 'Free Shuttle') {
                return 'fa-bus';
            }
            if ($shuttleOption === 'Free Ride Share' || $shuttleOption === 'Free Taxi') {
                return 'fa-taxi';
            }
            if ($shuttleOption === 'Walk to Airporcard-img-leftt') {
                return 'fa-male';
            }
            if ($shuttleOption === 'Metro to Airport') {
                return 'fa-train';
            }
            return 'fa-map-signs';
        },
    },
};
</script>

<style lang="scss">
$fontFamily: 'Expressway', sans-serif;

.closest-lots {
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: -12px;
        list-style-type: none;
        padding: 0;
    }
    &__item {
        box-sizing: border-box;
        flex: 0 0 100%;
        padding: 0 12px;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition: max-height 0.3s, opacity 0.2s;
        &.is-visible {
            max-height: 800px;
            overflow: auto;
            opacity: 1;
            padding: 12px;
        }
    }
    &__btn {
        display: flex;
        justify-content: center;
        .show-more-btn {
            margin-top: 24px;
        }
    }
}

.l-card {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    height: 100%;
    font-family: $fontFamily;
    color: #212529;
    padding: 16px;
    &__img {
        flex: 0 0 100px;
        max-width: 100px;
        object-fit: contain;
    }
    &__text {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding-left: 16px;
    }
    &__title {
        font-size: 1.063rem;
        font-size: 1.313rem;
        font-weight: 400;
        margin-bottom: 5px;
        color: #343a40;
    }
    &__rating {
        margin-bottom: 12px;
    }
    &__bottom {
        margin-top: auto;
        display: flex;
        align-items: flex-end;
    }
    &__info {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 8px;
        .fa {
            font-size: 0.875rem;
            margin-bottom: -3px;
        }
        .fa-plane {
            margin-right: 5px;
            transform: rotate(315deg);
        }
        .fa-bus {
            margin-right: 6px;
        }
    }
    &__btn {
        margin-left: auto;
        padding-left: 10px;
        flex-shrink: 0;
        .btn {
            padding: 7px 24px;
            font-size: 1.125rem;
            font-size: 1.688rem;
        }
    }
}

.show-more-btn {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    color: #25a0de;
    font-size: 1.125rem;
    font-weight: 600;
    font-family: $fontFamily;
    background: transparent;
    border: none;
    &__up {
        display: none;
    }
    &.is-hide {
        .show-more-btn__up {
            display: block;
        }
        .show-more-btn__down {
            display: none;
        }
    }
}

@media (min-width: 992px) {
    .closest-lots__item {
        flex: 0 0 50%;
    }
}
</style>
