<template>
    <div class="airport-dialogbox shadow text-center py-3 px-4" v-if="showDialog">
        <div class="question text-primary">Did you mean to select this airport?</div>
        <div class="airport text-dark"><i class="fa fa-plane mr-2"></i> {{ address.name }}</div>
        <div class="buttons">
            <button class="btn btn-link text-primary" @click.prevent="hideDialog()">No</button>
            <button class="btn btn-link text-primary" @click.prevent="setLocationInStore()">Yes</button>
        </div>
    </div>
</template>

<script>
    import * as VueGoogleMaps from 'vue2-google-maps'

    export default {
        data() {
            return {
                showDialog: false,
                geocoder: null,
                webhooks_path: 'https://www.shuttlefinder.com/webhooks/closest-airport',
                variable: null,
                address: null,
                isGoogleMapsLoaded: false,
            }
        },

        mounted() {
            this.$root.$on('searchbox::place-selected', this.initAndCheck);
        },

        methods: {
            initAndCheck(place) {
                if (this.isGoogleMapsLoaded) {
                    this.checkPlace(place);
                }

                //google
                Vue.use(VueGoogleMaps, {load: window.googleMapsConfig});
                VueGoogleMaps.loaded.then(() => {
                    this.geocoder = new google.maps.Geocoder();
                    this.isGoogleMapsLoaded = true;
                    this.checkPlace(place);
                })
            },

            checkPlace(place) {
                if (place.type === 'address') {
                    this.variable = place.variable;

                    this.geocoder.geocode({'placeId': place.place_id}, (results, status) => {
                        if (status === 'OK') {
                            let place = _.get(results, '[0]');
                            let params = {
                                lat: place.geometry.location.lat(),
                                lng: place.geometry.location.lng()
                            };

                            this.$http.get(this.webhooks_path, {
                                params: params
                            }).then(this.processResponse);
                        }
                    });
                }
            },

            processResponse(response) {
                if (!_.get(response, 'data.place_id', null)) {
                    return;
                }

                this.address = {
                    place_id: _.get(response, 'data.place_id'),
                    name: _.get(response, 'data.address.name'),
                    formatted_address: _.get(response, 'data.address.formatted_address'),
                    type: 'airport',
                    airport_code: _.get(response, 'data.code'),
                };

                //this.showDialog = true
                this.setLocationInStore();
            },

            hideDialog(){
                this.showDialog = false

                // Tracking Stuff Here
            },

            setLocationInStore() {
                this.$store.dispatch('update' + this.variable.capitalize(), this.address)
                //this.showDialog = false

                // Tracking Stuff Here
            },
        }
    }
</script>

<style lang="scss" scoped>
    .airport-dialogbox{
        position: absolute;
        top: 1.75rem;
        left: 0;
        right: 0;
        max-width: 300px;
        margin: 0 auto;
        background: white;
        border-radius: 5px;
        z-index: 10;
        .question{
            font-size: 0.75rem;
        }
        .airport{
            margin: 1rem 0;
            font-size: 1.125rem;
        }
        .buttons{
            button{
                font-size: 0.9rem;
                text-decoration: none;
                transition: color 0.33s ease-in-out;
                &:hover{
                    color: #55D6BE;
                }
            }
        }
    }
</style>
