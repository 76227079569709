
Vue.component('page-unsubscribe', {

    data() {
        return {
            email: null,

            successMessage: null,
            errorMessage: null,
        }
    },

    mounted() {
        this.initForm()
    },

    computed: {},

    watch: {},

    methods: {

        initForm() {
            let self = this
            let requesting = false;
            let $form = $(this.$el).find("form")

            $form.parsley().on("form:submit", function () {
                $form.find("input").each(function () {
                    $(this).parsley().removeError('custom-error');
                });
                return false;

            }).on('form:success', function (formInstance) {

                if (requesting) {
                    return false;
                }
                requesting = true;
                self.successMessage = null
                self.errorMessage = null

                self.$http.post('/newsletters/unsubscribe', self.getParams()).then(response => {
                    self.successMessage = response.data.message

                }, response => {
                    let errors = response.body

                    for (var errorName in errors) {
                        if (errorName == 'errors') {
                            self.errorMessage = self.filters.formatMessage(errors[errorName])
                        }

                        let $field = $form.find(`input[name="${errorName}"]`);

                        if ($field.length) {
                            $field.parsley().addError('custom-error', {message: errors[errorName]});
                        }
                    }

                    requesting = false;
                });

                return false;
            });
        },

        getParams() {
            return {
                email: this.email,
            };
        }
    }
});
