import * as LDClient from 'launchdarkly-js-client-sdk'

class LDClientWrapper {
    constructor() {
        this.client = undefined
    }

    initialize() {
        const clientId = window.apr && window.apr.launch_darkly_client_id ? window.apr.launch_darkly_client_id : '';

        if (clientId && !this.client) {
            this.client = LDClient.initialize(clientId, { anonymous: true }, { streaming: false });
        }
    }

    close() {
        if (this.client) {
            this.client.close()
        }
    }

    getFeatureFlag(flag, defaultValue = false) {
        if (this.client === undefined) {
            return false
        }
        return this.client.variation(flag, defaultValue);
    }
}

const ldClientWrapper = new LDClientWrapper()
export default ldClientWrapper
