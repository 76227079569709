<template>
    <div class="options row m-0">
        <label class="col-12 col-xl-3 p-0 mr-xl-3 mb-2" v-if="$parent.type != 'searchbox-hotel'">
            <div class="heading mb-1">Departure {{ $parent.isCruiseport ? 'Cruise Port' : 'Airport' }}</div>
            <algolia-typeahead
                id="searchbox-airport"
                name="searchbox-airport"
                ref="algoliaTypeahead"
                :airport="query.airport"
                :placeholder="$parent.isCruiseport ? 'Enter departure cruise port' : 'Enter departure airport'">
            </algolia-typeahead>
        </label>

        <div class="col p-0 row m-0 mb-2 dates">
            <label class="col-sm-6 p-0 pr-sm-3 date_input">
                <div class="heading mb-1">{{ getFieldTitle(getPackageType(), 0) }}</div>
				<span class="date-text" :class="{ 'date-text--placeholder': !query.date1 }">{{ query.date1 ? $parent.formatDate(query.date1) : 'Select Date' }}</span>
                <input type="text" @blur="dateChanged('checkin')" name="checkindate" v-model="query.date1" data-field="date1" placeholder="Select Date"
                       data-parsley-required-message="Please select a date." data-toggle="tooltip" :data-original-title="getFieldTooltip(getPackageType(), 0)" readonly required>
                <i class="apr-icon-calendar input-icon mr-3"></i>
            </label>
            <label class="col-sm-6 p-0 pl-sm-0 date_input">
                <div class="heading mb-1">{{ getFieldTitle(getPackageType(), 1) }}</div>
				<span class="date-text" :class="{ 'date-text--placeholder': !query.date2 }">{{ query.date2 ? $parent.formatDate(query.date2) : 'Select Date' }}</span>
                <input type="text" @blur="dateChanged('checkout')" name="checkoutdate" v-model="query.date2" data-field="date2" placeholder="Select Date"
                       data-parsley-required-message="Please select a date." data-toggle="tooltip" :data-original-title="getFieldTooltip(getPackageType(), 1)" readonly required>
                <i class="apr-icon-calendar input-icon"></i>
            </label>
        </div>

        <div class="col-12 col-xl-2 p-0 pl-xl-3 my-3 my-xl-0">
            <div class="d-none d-xl-block heading mb-1 invisible">Submit</div>
            <button v-if="isPageSpiritAirlines()" type="submit" class="btn btn-big btn-block p-2 search-button" :style="{'min-width': $parent.type != 'searchbox-inline' ? '100px' : '180px' }">
                <span v-if="!$parent.inProgressSearch && $parent.type === 'searchbox'">SEARCH</span>
                <span v-if="!$parent.inProgressSearch && $parent.type === 'searchbox-inline'" style="white-space: nowrap">UPDATE SEARCH</span>
                <span v-if="!$parent.inProgressSearch && $parent.type === 'searchbox-hotel'" style="white-space: nowrap">UPDATE</span>
                <i class="fas fa-spinner fa-spin" aria-hidden="true" v-if="$parent.inProgressSearch"></i>
            </button>
            <button v-else type="submit" class="btn-brand-primary" :style="{'min-width': $parent.type != 'searchbox-inline' ? '100px' : '180px' }">
                <span v-if="!$parent.inProgressSearch && $parent.type === 'searchbox'">SEARCH</span>
                <span v-if="!$parent.inProgressSearch && $parent.type === 'searchbox-inline'" style="white-space: nowrap">UPDATE SEARCH</span>
                <span v-if="!$parent.inProgressSearch && $parent.type === 'searchbox-hotel'" style="white-space: nowrap">UPDATE</span>
                <i v-if="!$parent.inProgressSearch" class="apr-arrow-right" aria-hidden="true"></i>
                <span v-if="$parent.inProgressSearch" class="btn-brand-primary__loading-container" aria-hidden="true">
                  <span class="btn-brand-primary__loading"></span>
                </span>
            </button>
            <span class="cancel" v-if="$parent.isEditMode" :style="{'min-width': $parent.type != 'searchbox-inline' ? '100px' : '180px' }">
                <a href="#" @click.prevent="$parent.isEditMode = false; $parent.setTab($parent.sessionTab);">Cancel</a>
            </span>
        </div>
    </div>
</template>

<script>
    import AlgoliaTypeahead from '../components/AlgoliaTypeahead'
    import Datepicker from './../../datepicker'
    import { mapGetters, mapActions } from 'vuex'

    export default {
        mixins: [Datepicker],
        components: {AlgoliaTypeahead},

      data(){
          return {
            currentUrl: ''
          }
      },
      created() {
        this.currentUrl = window.location.pathname;
      },

        mounted() {
            if (this.isTouchDevice() === false) {
                $('[data-toggle="tooltip"]').tooltip();
            }
            this.$parent.initSearchboxDatepickers($(this.$el), {productType: 'apr'})
        },

      computed: {
            ...mapGetters([
                'session', 'query',
            ]),
        },

        methods: {
            isTouchDevice(){
                return true == ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch);
            },

            getFieldTitle(_package, _index) {
                if (! _package) { _package = 'PSF' }
                return this.$parent.titles[_package].dates[_index]
            },

            getFieldTooltip(_package, _index) {
                if (! _package) { _package = 'PSF' }
                return this.$parent.titles[_package].tooltips[_index]
            },

            getPackageType() {
                if (this.currentTab == 'hotel') { return 'PSF' }
                if (this.currentTab == 'shuttle') { return 'SF' }
                return 'APR'
            },

            dateChanged(when) {
                if (when == 'checkin') {
                    this.triggerSearchboxEvent(apr.event_category,`Check-in date entered ${this.$parent.currentTab}`, '')
                } else {
                    this.triggerSearchboxEvent(apr.event_category,`Check-out date entered ${this.$parent.currentTab}`, '')
                }
            },

          isPageSpiritAirlines()
          {
            return this.currentUrl === '/spirit-airlines'
          },

            triggerSearchboxEvent(action, label = '') {
                dataLayer.push({
                    'event': 'GaEvent',
                    'EventCategory': apr.event_category,
                    'EventAction': action,
                    'EventLabel': label
                });
            },
        }
    };
</script>
