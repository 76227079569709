export default {
    methods: {
        initBase() {
            this.initSignInModals();
            this.sessionStatusRequest();
        },

        sessionStatusRequest() {
            //TODO: update return url in the meta header?
            this.$http.get('/session').then(response => {
                this.$root.$emit('session-received', response.body)
            });
        },

        initSignInModals() {

            // signin_modal
            this.initModal(".sign-in", "/signin_modal", "#sign-in-modal", function ($form) {
                return {
                    email: $form.find('input[name="email"]').val(),
                    password: $form.find('input[name="password"]').val(),
                    return_url: $('meta[name=return_url]').attr("content"),
                };
            }, function (response) {
                if (response.body.redirect) {
                    window.location.href = response.body.redirect;
                }
            });


            // signup_modal
            this.initModal(".sign-up", "/signup_modal", "#sign-up-modal", function ($form) {
                return {
                    firstname: $form.find('input[name="firstname"]').val(),
                    lastname: $form.find('input[name="lastname"]').val(),
                    email: $form.find('input[name="email"]').val(),
                    password: $form.find('input[name="password"]').val(),
                    newsletter: $form.find('input[name="newsletter"]').prop("checked"),
                    return_url: $('meta[name=return_url]').attr("content"),
                };
            }, function (response) {
                if (response.body.redirect) {
                    window.location.href = response.body.redirect;
                }
            });


            // forgot_password_modal
            this.initModal(".forgot-password", "/forgot_password_modal", "#forgot-password-modal", function ($form) {
                return {
                    email: $form.find('input[name="email"]').val()
                };
            }, function (response, $modal) {
                $modal.find(".success .forgot-password-text").html(response.body);
                $modal.find("form").hide();
                $modal.find(".success").show();
            });

            // $(document).on('hidden.bs.modal', '.modal-apr', function (e) {
            //     $(e.target).remove();
            // })
        },

        initModal: function (selector, backendUrl, modalId, paramsCallback, responseSuccessCallback) {
            const self = this

            $(document).on("click", selector, function (e) {
                e.preventDefault();

                $(".modal-apr").modal("hide")

                let $modal_ = $(modalId);

                if ($modal_.length == 0) {
                    self.$http.get(backendUrl).then(response => {
                        $("body").append(response.bodyText);

                        let $modal = $(modalId);
                        let $form = $modal.find("form");

                        $modal.modal("show");

                        $form.parsley().on("form:submit", function () {
                            return false;

                        }).on("form:validate", function () {
                            $form.find(".parsley-custom-error").remove();

                        }).on('form:success', function (formInstance) {
                            self.$http.post($form.attr("action"), paramsCallback($form)).then(response => {
                                dataLayer.push({'event': 'GaEvent', 'EventCategory':'Sign In', 'EventAction': 'Success', 'EventLabel': ''})
                                responseSuccessCallback(response, $modal)

                            }, response => {
                                let errors = response.body.errors
                                dataLayer.push({'event': 'GaEvent', 'EventCategory':'Sign In', 'EventAction': 'Error', 'EventLabel': ''})


                                for (var errorName in errors) {
                                    let $field = $form.find(`input[name="${errorName}"]`);

                                    if ($field.length) {
                                        $field.parsley().addError('custom-error', {message: errors[errorName]});
                                    }
                                }
                            });
                        });

                    });

                } else {
                    $modal_.modal("show");
                }
            });
        }
    }
}
