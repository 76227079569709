import FSFormater from '../../mixins/FSFormater'
import FlightstatusSearchboxAirport from '../../components/FlightStatus/FlightStatusSearchboxAirport'

Vue.component('page-flightstatus-airport', {

    components: { FlightstatusSearchboxAirport },

    mixins: [ FSFormater ],

    data() {
        return {
            results: {},
            type: apr.type,
            isLoading: false,
	        searchboxEdit: true,
	        airport: apr.content.content.content,
	        information: apr.content,
	        hasSearched: false,
        }
    },
	
	watch: {
        'isLoading': function () {
	        this.hasSearched = true;
        },
		'searchboxEdit': function () {
		}
	},

    mounted() {},
    
    beforeUpdate() {
        $(this.$refs.amazon).addClass('d-flex')
        $(this.$refs.amazon).addClass('justify-content-center')
    }
});
