export default {
    methods: {
        initSocialLoginButtons: function () {
            let self = this;
            $(document).on("click", ".social-buttons a", function (e) {
                e.preventDefault();

                let provider = $(this).data("provider");
                let url = `${socialAuth.redirectorDomain}/${provider}?mobile=${socialAuth.mobile}&return_url=${socialAuth.callbackUrlFromAuthSiteToCurrentSite}`;

                self.socialLoginPopupWindow(url, 500, 600);
            })
        },

        socialLoginPopupWindow: function (url, w, h) {
            var left = (screen.width / 2) - (w / 2);
            var top = (screen.height / 2) - (h / 2);
            return window.open(url, 'APR Social Login', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
        }
    }
}