export default {
    methods: {
        getLotCategory(providerName) {
            return providerName === 'APR'
                ? "Airport Parking Only"
                : providerName === 'PSF'
                    ? "Hotel & Parking Package"
                    : "";
        }
    }
}
