Vue.component('app-header', {
    data: function () {
        return {
            id: null,
            role: 'guest',
            showMobileNav: false,
            activeMenuItem: '',
            activeSubMenuItem: ''
        };
    },

    mounted() {
        let self = this;

        this.$root.$on('session-received', function (data) {
            let login = data.login;

            self.id = login.id;
            self.role = login.role;
        });

        // this.setMenuItemsHeight();
    },

    methods: {
        // Set equal height for all submenus of menu
        setMenuItemsHeight() {
            [...document.querySelectorAll('[data-dd-item]')].forEach(
                (menuItem) => {
                    const menuSubitems =
                        menuItem.querySelectorAll('.top-nav__sublist');

                    const menuHeight = menuItem.getBoundingClientRect().height;
                    const maxSubmenuHeight = [...menuSubitems].reduce(
                        (maxHeight, subitem) => {
                            const currentSubitemHeight =
                                subitem.getBoundingClientRect().height;
                            return Math.max(maxHeight, currentSubitemHeight);
                        },
                        0
                    );

                    menuItem.style.height = `${Math.max(
                        menuHeight,
                        maxSubmenuHeight
                    )}px`;

                    // Update sublist styles after adding `height-calculated` class
                    menuItem.classList.add('height-calculated');
                }
            );
        },

        changeActiveElement(type, value) {
            if (window.innerWidth < 1281) return;
            this[type] = value;
        },
        toggleActiveElement(type, value) {
            if (window.innerWidth > 1280) return;
            this[type] = this[type] === value ? '' : value;
        },
        a11yMenu($event) {
            const { code, target } = $event;

            // Helper functions
            function goToNextElement(target) {
                if (
                    target.closest('li') &&
                    target.closest('li').nextElementSibling &&
                    target
                        .closest('li')
                        .nextElementSibling.querySelector('[data-menu-level]')
                ) {
                    target
                        .closest('li')
                        .nextElementSibling.querySelector('[data-menu-level]')
                        .focus();
                }
            }

            function goToPreviousElement(target) {
                if (
                    target.closest('li') &&
                    target.closest('li').previousElementSibling &&
                    target
                        .closest('li')
                        .previousElementSibling.querySelector(
                            '[data-menu-level]'
                        )
                ) {
                    target
                        .closest('li')
                        .previousElementSibling.querySelector(
                            '[data-menu-level]'
                        )
                        .focus();
                }
            }

            function isMenuLevel(target, level) {
                return target.getAttribute('data-menu-level') === level;
            }

            function removeActiveElement(target) {
                if (
                    target.closest('ul') &&
                    target.closest('ul').querySelector('.is-active')
                ) {
                    target
                        .closest('ul')
                        .querySelector('.is-active')
                        .classList.remove('is-active');
                }
            }

            function openMenu(target) {
                if (target.closest('li')) {
                    target.closest('li').classList.add('is-active');
                }
                target.setAttribute('aria-expanded', true);
            }

            function closeMenu(target) {
                const id = target.closest('ul')
                    ? target.closest('ul').getAttribute('id')
                    : null;
                const btn = document.querySelector(`[aria-controls="${id}"]`);
                if (id && btn) {
                    btn.focus();
                    btn.setAttribute('aria-expanded', false);
                    if (btn.closest('li')) {
                        btn.closest('li').classList.remove('is-active');
                    }
                }
            }

            // Prevent scrolling
            if (
                code === 'Space' ||
                code === 'ArrowLeft' ||
                code === 'ArrowRight' ||
                ((code === 'ArrowUp' || code === 'ArrowDown') &&
                    !isMenuLevel(target, '1'))
            ) {
                $event.preventDefault();
            }

            if (
                (code === 'Enter' || code === 'Space') &&
                target.getAttribute('aria-haspopup')
            ) {
                openMenu(target);
                return;
            }

            if (code === 'ArrowRight' && target.getAttribute('aria-haspopup')) {
                if (isMenuLevel(target, '1')) {
                    goToNextElement(target);
                    return;
                }
                openMenu(target);
                if (target.getAttribute('aria-expanded') === 'true') {
                    if (target.closest('li')) {
                        const firstElement = target
                            .closest('li')
                            .querySelector('ul li a');
                        if (firstElement) {
                            firstElement.focus();
                        }
                    }
                }
                return;
            }

            if (code === 'Escape') {
                closeMenu(target);
                return;
            }

            if (code === 'ArrowLeft') {
                if (isMenuLevel(target, '1')) {
                    goToPreviousElement(target);
                }
                if (isMenuLevel(target, '3')) {
                    closeMenu(target);
                }
                return;
            }

            if (code === 'ArrowDown') {
                if (isMenuLevel(target, '2') || isMenuLevel(target, '3')) {
                    goToNextElement(target);
                    removeActiveElement(target);
                    return;
                }
            }

            if (code === 'ArrowUp') {
                if (isMenuLevel(target, '2') || isMenuLevel(target, '3')) {
                    goToPreviousElement(target);
                    removeActiveElement(target);
                    return;
                }
            }
            if (code === 'Tab') {
                // When tab moves outside active submenu, it should close
                if (isMenuLevel(target, '2') || isMenuLevel(target, '3')) {
                    const activeParentLiSelector = isMenuLevel(target, '2')
                        ? '.top-nav'
                        : '.top-nav__list';
                    const activeLi = document.querySelector(
                        `${activeParentLiSelector} > .is-active`
                    );
                    if (
                        activeLi &&
                        target.matches(
                            `${activeParentLiSelector} li:last-child a, ${activeParentLiSelector} li:last-child button`
                        )
                    ) {
                        activeLi.classList.remove('is-active');
                    }
                }
            }
        },
    },
});
