Vue.component('page-customer-reservations', {

    data: function () {
        return {
            customer: apr.customer,
            showOpened: true,
            showPast: false,
            showHotels: false,
            tab: 'opened',
            tabData: apr.opened,
            psfTabData: apr.psf,

            isPsfAvailable: false,

            modalInfo: {},
            modalResendAction: null,
            modalType: null,
            modalInput: null,
            modalReservationId: null,

            modalSuccessMessage: null,
            modalErrorMessage: null,

            // cancellation data
            apr: {
                modalCancellationAction: null,
                modalCancellationInProgress: false
            },

            psf: {
                modalCancellationAction: null,
                modalCancellationReason: '',
                modalCancellationStep: 1,
                modalCancellationHotelPhone: null,
            },
        }
    },

    mounted() {
        this.initModalForm()

        if (apr.psf) {
            this.isPsfAvailable = true
        }
    },

    watch: {
        'psf.modalCancellationStep': function (step) {
            if (step == 3) {
                this.psfCancelReservation()
            }
        }
    },

    computed: {
        isNoTabReservations() {
            if (this.tab === 'opened' || this.tab === 'past') {
                return (this.tabData.length === 0);
            }

            if (this.tab === 'hotels') {
                return (this.psfTabData.length === 0);
            }
        },
    },

    methods: {
        setTab(tab){
            this.tab = tab;

            if (tab == 'hotels') {
                this.showOpened = false
                this.showPast = false
                this.showHotels = true

            } else if (tab == 'past') {
                this.showOpened = false
                this.showPast = true
                this.showHotels = false
                this.tabData = apr.past

            } else { // opened
                this.showOpened = true
                this.showPast = false
                this.showHotels = false
                this.tabData = apr.opened
            }
        },

        showReceiptModal(type, reservation){
            this.modalSuccessMessage = null
            this.modalResendAction = reservation.resendAction
            this.modalReservationId = reservation.id
            this.modalType = type

            if (type == 'email') {
                this.modalInput = apr.customer.email
                this.modalInfo = {
                    'title': 'EMAIL',
                    'body': 'email address',
                    'placeholder': 'Email',
                }

            } else if (type == 'fax') {
                this.modalInput = ''
                this.modalInfo = {
                    'title': 'FAX',
                    'body': 'fax number',
                    'placeholder': 'Fax number',
                }
            }

            $('#modal-receipt').modal('show')
        },

        initModalForm(){
            let self = this
            let requesting = false
            let $form = $('#modal-receipt form')

            $form.parsley().on("form:submit", function () {
                $form.find("input").each(function () {
                    $(this).parsley().removeError('custom-error');
                });
                return false;

            }).on('form:success', function (formInstance) {
                if (requesting) {
                    return false;
                }
                requesting = true;
                self.modalSuccessMessage = null
                self.modalErrorMessage = null

                self.$http.post(self.getModalResendAction(), self.getDataForReceipt()).then(response => {
                    self.modalSuccessMessage = response.data.message
                    requesting = false;

                }, response => {
                    let errors = response.body

                    for (var errorName in errors) {
                        if (errorName == 'errors') {
                            self.modalErrorMessage = self.filters.formatMessage(errors[errorName])
                        }

                        let $field = $form.find(`input[name="${errorName}"]`);

                        if ($field.length) {
                            $field.parsley().addError('custom-error', {message: errors[errorName]});
                        }
                    }

                    requesting = false;
                });

                return false;
            });
        },

        getModalResendAction() {
            return this.modalResendAction
        },

        getDataForReceipt() {
            if (this.modalType == 'email') {
                return {type: this.modalType, email: this.modalInput}
            }
            return {type: this.modalType, fax: this.modalInput}
        },


        aprShowCancellationModal(reservation) {
            this.modalReservationId = reservation.id
            this.apr.modalCancellationAction = reservation.cancellationAction
            this.apr.modalCancellationInProgress = false

            this.resetModalMessages()

            $('#modal-cancel').modal('show')
        },

        aprCancelReservation() {
            let self = this

            if (self.apr.modalCancellationInProgress) {
                return false;
            }
            self.apr.modalCancellationInProgress = true;
            self.resetModalMessages()

            self.$http.delete(self.apr.modalCancellationAction).then(response => {
                self.modalSuccessMessage = response.data.message
                self.apr.modalCancellationInProgress = false;

                // hide cancel button
                for (var key in apr.opened) {
                    if (apr.opened[key].id == self.modalReservationId) {
                        apr.opened[key].cancelled = true
                    }
                }
                self.setTab('opened');
                dataLayer.push({
                    'event': 'GaEvent',
                    'EventCategory': 'Customer',
                    'EventAction': 'Cancel Reservation',
                    'EventLabel': ''
                });

            }, response => {
                let errors = response.body
                for (var errorName in errors) {
                    self.modalErrorMessage = self.filters.formatMessage(errors[errorName])
                }
                self.apr.modalCancellationInProgress = false;
            });
        },

        // PSF
        psfShowCancellationModal(reservation) {
            this.modalReservationId = reservation.reservation_id
            this.psf.modalCancellationAction = reservation.cancellationAction
            this.psf.modalCancellationHotelPhone = reservation.hotel_phone
            this.psf.modalCancellationReason = ''

            this.resetModalMessages()

            $('#modal-psf-cancel').modal('show')
        },


        psfCancelReservation() {
            let self = this

            self.resetModalMessages()

            self.$http.delete(self.psf.modalCancellationAction, {
                params: {
                    reason: self.psf.modalCancellationReason
                }
            }).then(response => {
                self.modalSuccessMessage = response.data.message

                // hide cancel button
                for (var key in apr.psf) {
                    if (apr.psf[key].reservation_id == self.modalReservationId) {
                        apr.psf[key].status = 'Cancelled'
                    }
                }
                dataLayer.push({
                    'event': 'GaEvent',
                    'EventCategory': 'PSF Customer',
                    'EventAction': 'Cancel Reservation',
                    'EventLabel': ''
                });

            }, response => {
                let errors = response.body
                for (var errorName in errors) {
                    self.modalErrorMessage = self.filters.formatMessage(errors[errorName])
                }
            });
        },

        resetModalMessages() {
            this.modalSuccessMessage = null
            this.modalErrorMessage = null
        }
    }
});
