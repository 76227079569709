<template>

	<section id="flightstatus-search" v-if="$parent.searchboxEdit">
		<div class="container">

			<!-- Title -->
			<h1 v-if="!headingOne">{{ airline.default.name }} - Live Flight Status & Delays</h1>
			<h1 v-if="headingOne">{{ headingOne }}</h1>

			<div class="row m-0">

				<!-- Flight Airline -->
				<div class="item airline col-12 col-lg px-0 py-2 py-lg-0">
					<label>Airline</label>
					<div class="dropdown">
						<button class="btn dropdown-toggle" :class="{ 'null': !airline.selected.code }" type="button" id="FlightDirection" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span v-if="airline.selected.code">{{ airline.selected.name.capitalize() }} ({{ airline.selected.code.toUpperCase() }})</span>
							<span v-else>Select Airline</span>
						</button>
						<div class="dropdown-menu" aria-labelledby="FlightDirection">
							<button class="dropdown-item" v-for="item in airlines" @click.prevent="airline.selected = item">{{ item.name }} ({{ item.code.toUpperCase() }})</button>
						</div>
					</div>
				</div>

				<!-- Flight Departure Date -->
				<div class="item departure-date col-12 col-lg-3 px-0 pl-lg-3 py-2 py-lg-0">
					<label>Flight Departure Date</label>
					<div class="datepicker-container">
						<div class="datepicker-text">{{ datepicker_fullvalue ? formatDate(datepicker_fullvalue) : null }}</div>
						<datetimepicker class="departure-date h-auto"
										ref="datepicker"
										title="Departure"
										name="departureDate"
										autocomplete="off"
										autocorrect="off"
										autocapitalize="off"
										spellcheck="false"
										icon="fa-calendar"
										variable="date2"
										placeholder="Select flight departure date"
										v-model="datepicker_fullvalue"
										:config="datepicker_options">
						</datetimepicker>
					</div>
				</div>

				<!-- Flight Number -->
				<div class="item flight-number col-12 col-lg-3 px-0 pl-lg-3 py-2 py-lg-0">
					<label>Flight Number</label>
					<div class="flightnumber">
						<input class="w-100 border-0 rounded p-3" type="text" placeholder="Enter flight number" v-model="flight_number">
					</div>
				</div>

				<!-- Submit -->
				<div class="item col-12 col-lg-3 px-0 pl-lg-3">
					<div class="submit px-0">
						<button type="submit" class="btn-brand-primary" @click.prevent="search(false)">
							Search
							<i class="apr-arrow-right" aria-hidden="true"></i>
							<span v-show="working" class="btn-brand-primary__loading-container" aria-hidden="true">
								<span class="btn-brand-primary__loading"></span>
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section id="flightstatus-view" v-else>
		<div class="container px-5">
			<h1>Flights with {{ airline.default.name }}</h1>
			<h4>
				Departure Date<br>
				<span class="font-weight-lighter">{{ formatDate(datepicker_fullvalue) }}</span>
			</h4>
			<a href="#" @click.prevent="$parent.searchboxEdit = true">Edit Search</a>
		</div>
	</section>

</template>
<script>

	import FlightStatusMixin from '../../../../shared/js/FlightStatusMixin'
	import Datetimepicker from 'vue-bootstrap-datetimepicker'

	export default {

		mixins: [ FlightStatusMixin ],
		components: { Datetimepicker },

		props: {
			headingOne: { type: String, required: false },
			headingTwo:{ type: String, required: false },
		},

		data() {
			return {
				searchboxType: 'airline',
				tab: 'airline',
			}
		},

	}

</script>
