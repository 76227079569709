/**
 *  Load Vue Components
 */
require('./landing')
require('./airports')
require('./flightstatus')
require('./lot')
require('./receipt')
require('./directions')
require('./search')
require('./survey')
require('./checkout')
require('./us-home')
require('./unsubscribe')
require('./black-friday')
require('./seasonal')
// require('./scholarship')

require('./customer/customer-profile')
require('./customer/customer-reservations')

require('./help/help')
require('./help/faq')
require('./help/receipt')
require('./help/cancellation')
require('./help/contact')

require('./shuttle/all')
require('./psf/all')
require('./intermediate')
require('./intermediate-psf')

require('./flightstatus/airline')
require('./flightstatus/airport')
require('./flightstatus/information')

require('./password-reset')
