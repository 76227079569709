import Faqs from "./../../components/faq";

Vue.component('page-help-faq', {

    components: {Faqs},

    props: ['questionsType'],

    data() {
        return {
            questions: this.questionsType == 'APR' ? apr.apr_faqs : apr.psf_faqs,
            questions_list: [],
            open: false,
            faq_search: '',
            limit: 10,
            current: '',
            is_customer: false
        }
    },

    mounted() {
        this.create_list_of_questions()
    },

    computed: {
        //Filtering the suggestion based on the input
        matches() {
            let results = _.filter(this.questions_list, (suggest) => {
                this.faq_search_lower = this.faq_search.toLowerCase()

                if (suggest[0] == '10 Most Popular') {
                    return false;
                }

                return suggest[2].toLowerCase().indexOf(this.faq_search_lower) >= 0;
            })
            return results.slice(0, this.limit);
        },

        openDropdown() {
            return (this.matches.length != 0 && this.open === true);
        }
    },

    watch: {},

    methods: {
        create_list_of_questions(){
            for (let i = 0; i < this.questions.length; i++) {
                for (let f = 0; f < this.questions[i]['data'].length; f++) {
                    this.questions_list.push([this.questions[i]['name'], this.questions[i]['data'][f]['id'], this.questions[i]['data'][f]['question']])
                }
            }
        },

        faq_change(){
            if (this.open == false) {
                this.open = true;
            }
        },

        faq_blur(){
            setTimeout(() => {
                this.open = false
            }, 100)
        },

        faq_click(question){
            this.faq_search = question[2]
            this.current = {'category': question[0], 'question_id': question[1]}
            this.open = false

            dataLayer.push({
                'event': 'GaEvent',
                'EventCategory': this.questionsType + ' FAQ',
                'EventAction': 'Searched',
                'EventLabel': question[2]
            });
        },

        faq_reset() {
            this.faq_search = ''
            this.open = false
        }
    }
})
