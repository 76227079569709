<template>
	<section id="flightstatus-search">
		<div class="container">

			<!-- Title -->
			<h1>Flight Tracker - Live Time Real Status Info</h1>

			<!-- Tabs -->
			<div class="tabs">
				<div class="tab" :class="{ 'active': tab === 'airline' }" @click.prevent="selectTab('airline')">Track by Flight Number</div>
				<div class="tab" :class="{ 'active': tab === 'airport' }" @click.prevent="selectTab('airport')">Track by Airport Route</div>
			</div>

			<div class="row m-0">

				<!-- Flight Departure Airport -->
				<div class="item airport col-12 col-lg px-0 pl-lg-3 py-2 py-lg-0" :class="{ 'error': showErrors && !airport.departure.code }" v-if="tab === 'airport'">
					<label>Departure Airport</label>
					<algolia-typeahead
							id="airportSelect"
							placeholder="Enter Airport"
                            class="flightstatus-page-typeahead"
                            v-on:typeahead-change="setDepartureAirport">
					</algolia-typeahead>
					<div class="error-message">Please select an airport.</div>
				</div>

				<!-- Flight Arrival Airport -->
				<div class="item airport col-12 col-lg px-0 pl-lg-3 py-2 py-lg-0" :class="{ 'error': showErrors && !airport.arrival.code }" v-if="tab === 'airport'">
					<label>Arrival Airport</label>
					<algolia-typeahead
							id="airportSelect2"
							placeholder="Enter Airport"
                            class="flightstatus-page-typeahead"
                            v-on:typeahead-change="setArrivalAirport">
					</algolia-typeahead>
					<div class="error-message">Please select an airport.</div>
				</div>

				<!-- Flight Airline -->
				<div class="item airline col-12 col-lg px-0 pl-lg-3 py-2 py-lg-0" :class="{ 'error': showErrors && !airline.selected.code }" v-if="tab === 'airline'">
					<label>Airline</label>
					<div class="dropdown">
						<button class="btn dropdown-toggle" :class="{ 'null': !airline.selected.code }" type="button" id="FlightDirection" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span v-if="airline.selected.code">{{ airline.selected.name.capitalize() }} ({{ airline.selected.code.toUpperCase() }})</span>
							<span v-else>Select Airline</span>
						</button>
						<div class="error-message">Please select an airline.</div>
						<div class="dropdown-menu" aria-labelledby="FlightDirection">
							<button class="dropdown-item" v-for="item in airlines" @click.prevent="airline.selected = item">{{ item.name }} ({{ item.code.toUpperCase() }})</button>
						</div>
					</div>
				</div>

				<!-- Flight Departure Date -->
				<div class="item departure-date col-12 col-lg px-0 pl-lg-3 py-2 py-lg-0" :class="{ 'error': showErrors && !datepicker_fullvalue }">
					<label v-if="tab === 'airline'">Flight Departure Date</label>
					<label v-if="tab === 'airport'">Departure Date</label>
					<div class="datepicker-container">
						<div class="datepicker-text">{{ formatDate(datepicker_fullvalue) }}</div>
						<datetimepicker class="departure-date h-auto"
										title="Departure"
										name="departureDate"
										autocomplete="off"
										autocorrect="off"
										autocapitalize="off"
										spellcheck="false"
										icon="fa-calendar"
										placeholder="Select Date"
										variable="date2"
										ref="datepicker"
										v-model="datepicker_fullvalue"
										:config="datepicker_options"
						>
						</datetimepicker>
						<div class="error-message">Please select a date.</div>
					</div>
				</div>

				<!-- Flight Number -->
				<div class="item flight-number col-12 col-lg px-0 pl-lg-3 py-2 py-lg-0" :class="{ 'error': showErrors && !flight_number }" v-if="tab === 'airline'">
					<label>Flight Number</label>
					<div class="flightnumber">
						<input class="w-100 border-0 rounded p-3" type="text" placeholder="Enter flight number" v-model="flight_number" style="height: 54px">
					</div>
					<div class="error-message">Please enter flight number.</div>
				</div>

				<!-- Submit -->
				<div class="item col-12 col-lg px-0 pl-lg-3">
					<div class="submit px-0">
						<button type="submit" class="btn-brand-primary" @click.prevent="search()">
							Search
							<i class="apr-arrow-right" aria-hidden="true"></i>
							<span v-show="working" class="btn-brand-primary__loading-container" aria-hidden="true">
								<span class="btn-brand-primary__loading"></span>
							</span>
						</button>
					</div>
				</div>

			</div>
		</div>
	</section>
</template>
<script>
	import FlightStatusMixin from '../../../../shared/js/FlightStatusMixin'
	import AlgoliaTypeahead from '../../components/Searchbox/components/AlgoliaTypeahead'
	import Datetimepicker from 'vue-bootstrap-datetimepicker'

	export default {
		mixins: [ FlightStatusMixin ],
		components: { AlgoliaTypeahead, Datetimepicker },

		data() {
			return {
				searchboxType: 'multi',
				tab: 'airline',
			}
		},
	}
</script>
