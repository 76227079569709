import Modal from "../../components/BsModal.vue";
import Heading from "../../components/heading";
import ProductImagesSlider from "../../components/ProductImagesSlider.vue";
import ExpandableContent from '../../components/ExpandableContent.vue';
import BaseSlider from '../../components/NewTemplate/BaseSlider';

Vue.component('page-hotel', {

    components: {
        Modal,
        ProductImagesSlider,
        ExpandableContent,
        BaseSlider
    },

    mixins: [Heading],

    data: function () {
        return {
            showFooter: false,
            showAllPhotos: false,

            token: apr.token,
            hotel: apr.hotel,
            airport: _.get(window, 'apr.hotel.airport'),
            dates: {
                checkindate: apr.search ? apr.search.date1 : '',
                checkoutdate: apr.search ? apr.search.date2 : '',
            },
            packages: this.hotel ? this.hotel.packages : '',

            searchbox: {},
            search_packages: null,
            descriptions: null,

            inProgressSearch: false,

            available: true,
            errorMessage: '',
            unavailabilityType: null,
            initSlider: false,
            showMoreReviews: false,
            productsSliderKey: 0,
        }
    },

    mounted() {
        let self = this
        let psf_search_results = _.get(window, 'apr.psf_search_results');

        // set from session (from PSF hotel search response)
        this.searchbox = _.get(psf_search_results, 'search');
        this.search_packages = _.get(psf_search_results, 'rates');

        this.available = _.get(psf_search_results, 'available', true);
        this.unavailabilityType = _.get(psf_search_results, 'unavailability_type', null);
        this.errorMessage = _.get(psf_search_results, 'error', null);

        this.hotel.overview.prices.avg = _.get(psf_search_results, 'best_rate');
        this.descriptions = _.get(psf_search_results, 'descriptions');

        dataLayer.push({
            'event' : 'airportCodeDetails',
            'airportCode': `${this.airport.code}`,
        });

        //dataLayer - productDetailImpressions
        this.$nextTick(function () {
            let category = this.hotel.provider === 'PSF'
                ? "Hotel & Parking Package"
                : "";
            let variant = typeof(this.available) != 'undefined' && this.available === true
                ? "available"
                : "sold out";
            let price = typeof(this.hotel.psf_search_results.best_rate) != 'undefined'
                ? this.hotel.psf_search_results.best_rate
                : typeof(this.hotel.psf_search_results.rates[0].package_rate != 'undefined')
                    ? this.hotel.psf_search_results.rates[0].package_rate
                    : "";
            dataLayer.push({
                'event': 'productDetailImpressions',
                'ecommerce': {
                    'detail': {
                        'actionField': {'list': 'Apparel Gallery'},
                        'products': [{
                            'name': `PSF ${this.airport.code} ${this.hotel.name}`,
                            'id': `PSF ${this.airport.code}`,
                            'price': price,
                            'brand': '',
                            'category': category,
                            'variant': variant
                        }]
                    }
                }
            })
        });

        // callbacks
        this.$root.$on('hotel-package-search', function (successData) {
            let successResponse = _.get(successData, 'body.data');

            self.updatePropertiesAfterSearch(successResponse);
        });

        this.$root.$on('hotel-package-search-failed', function (errorData) {
            let errorResponse = _.get(errorData, 'body.data');

            self.updatePropertiesAfterSearch(errorResponse);
        });
        
        // this.scrollStick();
        this.$nextTick(() => {
            this.initPopover();
        });

        $('body')
            .addClass('showFooter')
            .scrollspy({target: '.navbar-sticky', offset: 50});

        // tracking
        $(document).on("click", ".carousel-container .right", function () {
            self.triggerPageEvent('Photos', 'Next');
        });
        $(document).on("click", ".carousel-container .left", function () {
            self.triggerPageEvent('Photos', 'Prev');
        });

         $(document).on('shown.bs.modal', '#Gallery_Modal', () => {
            this.initSlider = true;
        });
    },

    watch: {
        'token': function (token) {
            this.$refs.searchboxHotel.isDefaultMode = !token;
        }
    },

    computed: {
        searchLink() {
            if (this.token && this.searchbox.id && this.searchbox.package !== 'APR' && this.searchbox.package !== 'HRO') {
                let airportCode = this.airport.code.toLowerCase()
                let queryData = {
                    checkindate: _.get(this.searchbox, 'date1.date_format_3', null),
                    checkoutdate: _.get(this.searchbox, 'date2.date_format_3', null),
                    token: this.token,
                }
                let qs = this.encodeQueryData(queryData)

                return `/psf_search/${airportCode}?` + qs;
            }

            return false;
        },

        packageTypeForErrorMessage() {
            return this.getAlternativePackageText(_.get(this.$store.getters, 'session.package'));
        },

        reviews() {
            return this.showMoreReviews ? this.hotel.reviews.data : this.hotel.reviews.data.slice(0, 3);
        }
    },

    methods: {
        updatePropertiesAfterSearch(response) {
            if (_.get(response, 'search')) {
                this.searchbox = _.get(response, 'search');
            }
            // apr search token
            if (_.get(response, 'apr_search')) {
                this.token = _.get(response, 'apr_search.token');
            }

            this.available = _.get(response, 'available', false);
            this.unavailabilityType = _.get(response, 'unavailability_type', null);
            this.errorMessage = _.get(response, 'error', null);

            this.hotel.overview.prices.avg = _.get(response, 'best_rate');
            this.search_packages = _.get(response, 'rates', null);

            if (_.get(response, 'alternatives')) {
                this.hotel.alternatives = _.get(response, 'alternatives');
                this.productsSliderKey += 1;
            }
            if (_.get(response, 'descriptions')) {
                this.descriptions = _.get(response, 'descriptions');
            }

            if (_.get(response, 'important_information')) {
                this.hotel.info.important_information = _.get(response, 'important_information');
                this.hotel.info.checkin_instructions = _.get(response, 'checkin_instructions');
            }

            this.$nextTick(() => {
                this.initPopover();
            });
        },

        initBackButton(response) {
            let airportCode = response.searchbox.airportCode.toLowerCase()
            let productUrl = window.location.href;
            let airportUrl = `/psf_search/${airportCode}?token=${response.searchbox.token}`;
            if (window.location.search == '?source=apr') {
                airportUrl = `/search/${airportCode}?token=${response.searchbox.token}`;
            }
            window.history.replaceState({action: "airport"}, "", airportUrl);
            window.history.pushState({action: "product"}, "", productUrl);

            window.addEventListener("popstate", function (event) {
                if (event.state && event.state.action === "airport") {
                    window.location = airportUrl;
                }
            }, false);
        },

        scrollStick() {
            $(window).scroll(function () {
                let height = $(window).scrollTop();
                let datesComp = $('.package-starting-from').offset();
                // let navbarComp = $('.navbar-container').offset();

                if (height > datesComp.top) {
                    $('.package-starting-from-sticky').addClass('sticky')
                } else {
                    $('.package-starting-from-sticky').removeClass('sticky')
                }

                // if (height > navbarComp.top) {
                //     $('.navbar-sticky').addClass('sticky')
                // } else {
                //     $('.navbar-sticky').removeClass('sticky')
                // }
            });
        },

        scrollTo(id) {
            $("html,body").animate({scrollTop: $(`#${id}`).offset().top - 48}, "slow");
        },

        goToCarouselSlide(index) {
            $('.carousel').carousel(index)
        },

        goToCheckout(roomId, rateId, searchId, provider = 'psf', index = null) {
            let self = this;
            let $button = index !== null ? $("#booknow-" + index) : null;

            // $button.html(`<i class="fas fa-spinner fa-spin" aria-hidden="true"></i>`);
            $button.html(`<span aria-hidden="true" class="btn-brand-primary__loading-container"><span class="btn-brand-primary__loading"></span></span>`);

            this.$http.post(_.get(window, 'apr.checkout_request_token_url'), {
                room_id: roomId,
                rate_id: rateId,
                search_id: searchId,
                provider: provider,
                hotel_id: this.hotel.hotel_id,
                token: this.token,

            }).then((response) => {
                let token = _.get(response, 'body.data.token');
                if (token) {
                    //dataLayer - addToCart
                    let category = this.hotel.provider === 'PSF'
                            ? "Hotel & Parking Package"
                            : "";
                    let variant = typeof(this.available) != 'undefined' && this.available === true
                            ? "available"
                            : "sold out";
                    let $priceHtmlTag = $button.parent().parent().children(".price").length === 1
                            ? $button.parent().parent().children(".price")
                            : $button.parent().parent().children(".room-price")
                    let price = parseFloat($priceHtmlTag.text().trim().replace("$", ""));

                    dataLayer.push({
                        'event': 'addToCart',
                        'ecommerce': {
                            'currencyCode': 'USD',
                            'add': {
                                'products': [{
                                    'name': `PSF ${this.airport.code} ${this.hotel.name}`,
                                    'id': `PSF ${this.airport.code}`,
                                    'price': price,
                                    'brand': '',
                                    'category': category,
                                    'variant': variant,
                                    'quantity': 1
                                }]
                            }
                        }
                    })

                    window.location.href = `${_.get(window, 'apr.checkout_page_url')}?token=${token}`;
                }

                self.triggerPageEvent('Buttons', 'Select Room - Proceeded To Checkout');

            }, (response) => {

                alert('Sorry we are unable to process you request.');
                $button.html('Book Now');
            })
        },

        triggerTabEvent(eventName) {
            this.scrollTo(eventName.toLowerCase())

            this.triggerPageEvent('Tabs', eventName)
        },

        triggerPageEvent(action, label = '') {
            dataLayer.push({
                'event': 'GaEvent',
                'EventCategory': 'PSF Hotel',
                'EventAction': action,
                'EventLabel': label
            })
        },

        getAlternativePackageText(pckg) {
            if (pckg === 'HRO') {
                return 'Hotel Room Only'
            } else if (pckg === 'PSF' || pckg === 'PSC') {
                return 'Sleep at Beginning of Trip'
            } else if (pckg === 'PFS' || pckg === 'PCS') {
                return 'Sleep at End of Trip'
            } else if (pckg === 'PSFS' || pckg === 'PSCS') {
                return 'Sleep at Beginning & End of Trip'
            }
        },

        encodeQueryData(data) {
            let ret = [];
            for (let d in data) {
                ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
            }
            return ret.join('&');
        },

        getNightsNumber(packageName) {
            return (packageName === 'PSFS' || packageName === 'PSCS') ? 2 : 1
        },

        getRoomAmenitiesHtml(amenities) {
            if (amenities && amenities.length) {
                return _.map(amenities, (amenity) => {
                    if (_.get(amenity, 'name')) {
                        return `<p><b>${_.get(amenity, 'name')}</b> - ${_.get(amenity, 'value')}</p>`;
                    }
                    return `<p>${_.get(amenity, 'value')}</p>`;

                }).join('');
            }
        },

        initPopover() {
            $('[data-toggle="popover"]').popover({
                fallbackPlacement: ['bottom'],
                offset: '50%p - 72px, 5px',
                template:
                    `<div class="popover" role="tooltip">
                        <div class="arrow"></div>
                        <button type="button" class="close"><span aria-hidden="true">&times;</span></button>
                        <div class="popover-body"></div>
                    </div>`
            });

            $('body').on('click', function (e) {
                $('[data-toggle="popover"]').each(function () {
                    if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                        $(this).popover('hide');
                    }
                });
            });

            $(document).on('click', '.close', {}, (e) => {
                $(e.target).closest('.popover').popover('hide')
            })
        },
    }
});
