import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
    strict: true,
    state: {
        // Current session search data
        session: {
            id: 0,
            airport: {
                type: '',
                code: '',
                name: '',
                city: '',
                state_code: '',
                latitude: '',
                longitude: '',
            },
            destination: {},
            date1: '',
            date2: '',
            dates: {},
            package: '',
            guests: 2,
            rooms: 1,
        },
        query: {
            id: 0,
            airport: {
                type: '',
                code: '',
                name: '',
                city: '',
                state_code: '',
                latitude: '',
                longitude: '',
            },
            destination: {},
            date1: '',
            date2: '',
            package: '',
            guests: 2,
            rooms: 1,
        },
        shuttlefinder: {
            trip_type: 'toAirport',
            pickup: {
                place_id: null,
                name: null,
                name_alternative: null,
                formatted_address: null,
                type: null,
                airport_code: null,
            },
            dropoff: {
                place_id: null,
                name: null,
                name_alternative: null,
                formatted_address: null,
                type: null,
                airport_code: null,
            },
            pickup_date: '',
            dropoff_date: '',
            return_pickup_date: '',
            return_dropoff_date: '',
            domestic: 1,
            provider: null,
            passengers: 1,
            return: false,
        },
        // Current Tab Selected
        currentTab: '',
        sessionTab: '',
    },
    getters: {
        // Place here for mapping.
        session: state => { return state.session },
        query: state => { return state.query },
	    shuttlefinder: state => { return state.shuttlefinder },
        currentTab: state => { return state.currentTab },
        sessionTab: state => { return state.sessionTab }
    },
    mutations: {
        updateSession: (state, payload) => {
            let date1 = null
            let date2 = null
            let dates = null

            // Set date correctly.
            if (payload.date1) {
                date1 = payload.date1 && payload.date1.date_format_2 ? payload.date1.date_format_2 : payload.date1;
                date2 = payload.date2 && payload.date2.date_format_2 ? payload.date2.date_format_2 : payload.date2;
            } else if (payload.checkindate) {
                date1 = payload.checkindate
                date2 = payload.checkoutdate
            }

            if (payload.dates) {
                dates = payload.dates
            } else if (apr.search_details && apr.search_details.dates) {
                dates = apr.search_details.dates
            }

            state.query = {
                id: payload.id ? payload.id : payload.token,
                airport: {
                    type: _.get(payload, 'airport.type'),
                    code: _.get(payload, 'airport.code'),
                    name: _.get(payload, 'airport.name'),
                    city: _.get(payload, 'airport.city'),
                    state_code: _.get(payload, 'airport.state_code'),
                    latitude: _.get(payload, 'airport.latitude'),
                    longitude: _.get(payload, 'airport.longitude'),
                },
                destination: _.size(payload.destination) ? payload.destination : {},
                date1: date1,
                date2: date2,
                dates: dates,
                package: payload.package,
                guests: payload.guests ? payload.guests : 2,
                rooms: payload.rooms ? payload.rooms : 1,
            };
            state.session = {
                id: payload.id ? payload.id : payload.token,
                airport: {
                    type: _.get(payload, 'airport.type'),
                    code: _.get(payload, 'airport.code'),
                    name: _.get(payload, 'airport.name'),
                    city: _.get(payload, 'airport.city'),
                    state_code: _.get(payload, 'airport.state_code'),
                    latitude: _.get(payload, 'airport.latitude'),
                    longitude: _.get(payload, 'airport.longitude'),
                },
                destination: _.size(payload.destination) ? payload.destination : {},
                date1: date1,
                date2: date2,
                dates: dates,
                package: payload.package,
                guests: payload.guests ? payload.guests : 2,
                rooms: payload.rooms ? payload.rooms : 1,
                shuttleType: payload.shuttleType ? payload.shuttleType : ''
            };
        },

        // Changing Tab Selected
        updateTab: (state, payload) => { state.currentTab = payload },
        updateSessionTab: (state, payload) => { state.sessionTab = payload },

        // Editting Package Type
        updatePackage: (state, payload) => { state.query.package = payload },

        // Editting Airport
        updateAirport: (state, payload) => { state.query.airport = payload },

        updateDestination: (state, payload) => { state.query.destination = payload },

        updateShuttleType: (state, payload) => { state.query.shuttleType = payload },

        // Editting Dates
        updateDates: (state, payload) => {
            state.query.date1 = payload.date1
            state.query.date2 = payload.date2
        },

        updateDate1: (state, payload) => { state.query.date1 = payload },
        updateDate2: (state, payload) => { state.query.date2 = payload },

        // Editting Guest Amounts
        addGuest: state => {
            state.query.guests += 1

            // Rooms can't have more than 4 people.
            if (state.query.guests / 4 > state.query.rooms) {
                state.query.rooms += 1
            }
        },
        removeGuest: state => {
            if (state.query.guests > 1) {	// Must have atleast 1 guest.
                state.query.guests -= 1
            }

            // Guests can't have more than 1 room each.
            if (state.query.guests < state.query.rooms) {
                state.query.rooms -= 1
            }
        },

        // Editting Room Amounts
        addRoom: state => {
            if (state.query.guests == state.query.rooms) {
                state.query.guests += 1
            }

            // Cannot have less rooms then people.
            if (state.query.guests < 4 || (state.query.guests != state.query.rooms)) {
                state.query.rooms += 1
            }
        },

        removeRoom: state => {
            if (state.query.rooms > 1) {	// Must have atleast 1 room.
                // Rooms can't have more than 4 people.
                console.log(state.query.guests + ' : ' + state.query.rooms)
                if ((state.query.guests / 4) > (state.query.rooms - 1)) {
                    return
                }

                state.query.rooms -= 1
            }
        },

        resetQueryDates: state => {
            state.query.date1 = ''
            state.query.date2 = ''
        },

        // Shuttlefinder mutations
        updateTripType: (state, payload) => { state.shuttlefinder.trip_type = payload },
        updateReturn: (state, payload) => { state.shuttlefinder.return = payload },
        updateDropoff: (state, payload) => { state.shuttlefinder.dropoff = payload },
        updateDropoff_date: (state, payload) => { state.shuttlefinder.dropoff_date = payload },
        updateReturn_dropoff_date: (state, payload) => { state.shuttlefinder.return_dropoff_date = payload },
        updatePickup: (state, payload) => { state.shuttlefinder.pickup = payload },
        updatePickup_date: (state, payload) => { state.shuttlefinder.pickup_date = payload },
        updateReturn_pickup_date: (state, payload) => { state.shuttlefinder.return_pickup_date = payload },
        updatePassengers: (state, payload) => { state.shuttlefinder.passengers = payload },
        updateDomestic: (state, payload) => { state.shuttlefinder.domestic = payload },
    },
    actions: {
        // Place here for mapping.
        updateSession: (context, payload) => { context.commit('updateSession', payload) },
        updateSessionTab: (context, payload) => { context.commit('updateSessionTab', payload) },
        updateTab: (context, payload) => { context.commit('updateTab', payload) },
        updatePackage: (context, payload) => { context.commit('updatePackage', payload) },
        updateAirport: (context, payload) => { context.commit('updateAirport', payload) },
        updateDestination: (context, payload) => { context.commit('updateDestination', payload) },
        updateDates: (context, payload) => { context.commit('updateDates', payload) },
        updateDate1: (context, payload) => { context.commit('updateDate1', payload) },
        updateDate2: (context, payload) => { context.commit('updateDate2', payload) },
        updateShuttleType: context => { context.commit('updateShuttleType') },
        addGuest: context => { context.commit('addGuest') },
        removeGuest: context => { context.commit('removeGuest') },
        addRoom: context => { context.commit('addRoom') },
        removeRoom: context => { context.commit('removeRoom') },
        resetQueryDates: context => { context.commit('resetQueryDates') },

        // Shuttlefinder Actions
        updatePickup: (context, payload) => {
            context.commit('updatePickup', payload);

            // context.dispatch('updateTripType');
        },

        updateDropoff: (context, payload) => {
            context.commit('updateDropoff', payload);

            // context.dispatch('updateTripType');
        },

        updateTripType: (context) => {
            let tripType = 'nonAirport'
            let locationType1 = context.getters.shuttlefinder.pickup.type
            let locationType2 = context.getters.shuttlefinder.dropoff.type

            let isType1Airport = (_.indexOf(['airport', 'cruiseport'], locationType1) !== -1)
            let isType2Airport = (_.indexOf(['airport', 'cruiseport'], locationType2) !== -1)

            if (locationType1 && locationType2) {
                if (isType1Airport && isType2Airport) {
                    tripType = 'airportToAirport'
                } else if (isType1Airport && isType2Airport === false) {
                    tripType = 'fromAirport'
                } else if (isType1Airport === false && isType2Airport) {
                    tripType = 'toAirport'
                } else {
                    tripType = 'nonAirport'
                }

                context.commit('updateTripType', tripType);
            }
        },

        updatePickup_date: (context, payload) => {
            context.commit('updatePickup_date', payload);
        },

        updateDropoff_date: (context, payload) => {
            context.commit('updateDropoff_date', payload);
        },

        updateReturn_pickup_date: (context, payload) => {
            context.commit('updateReturn_pickup_date', payload);
        },

        updateReturn_dropoff_date: (context, payload) => {
            context.commit('updateReturn_dropoff_date', payload);
        },

        updatePassengers: (context, payload) => {
            context.commit('updatePassengers', payload);
        },

        updateReturn: (context, payload) => {
            context.commit('updateReturn', payload);
        },

        updateDomestic: (context, payload) => {
            context.commit('updateDomestic', payload);
        },
    }
})
