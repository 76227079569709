import {mapGetters} from "vuex"

export default {
	
	data(){
		return {
			working: false,
			showErrors: false,
			airline: {
				default: {
					code: null,
					name: null,
				},
				selected: {
					code: null,
					name: null,
				},
			},
			flight_number: null,
			flight_direction: 'departures',
			airport: {
				default: {
					code: null,
					name: null,
				},
				departure: {
					code: null,
					name: null,
				},
				arrival: {
					code: null,
					name: null,
				}
			},
			airlines: {
				'AC': { code: 'ac', name: 'Air Canada'},
				'AS': { code: 'as', name: 'Alaska Airlines'},
				'G4': { code: 'g4', name: 'Allegiant Air'},
				'AA': { code: 'aa', name: 'American Airlines'},
				'BA': { code: 'ba', name: 'British Airways'},
				'DL': { code: 'dl', name: 'Delta Air lines'},
				'EK': { code: 'ek', name: 'Emirates'},
				'F9': { code: 'f9', name: 'Frontier Airlines'},
				'HA': { code: 'ha', name: 'Hawaiian Airlines'},
				'B6': { code: 'b6', name: 'JetBlue Airways'},
				'LH': { code: 'lh', name: 'Lufthansa'},
				'WN': { code: 'wn', name: 'Southwest Airlines'},
				'NK': { code: 'nk', name: 'Spirit Airlines'},
				'ua': { code: 'ua', name: 'United Airlines'},
				'VX': { code: 'vx', name: 'Virgin America'},
				'WS': { code: 'ws', name: 'WestJet'},
			},
			datepicker_options: {
				format: 'YYYY-MM-DD',
				minDate: moment().subtract(3, 'd').format(),
				maxDate: moment().add(3, 'd').format(),
				defaultDate: moment().startOf('hour').format(),
				disabledDates: [moment().add(3, 'd').format()],
				stepping: 15,
				useCurrent: false,
				showClear: false,
				showClose: false,
				collapse: false,
				sideBySide: false,
			},
			datepicker_value: null,
			datepicker_fullvalue: null,
			minDate: new Date(new Date().setDate(new Date().getDate() - 3)),
			maxDate: new Date(new Date().setDate(new Date().getDate() + 2)),
		}
	},
	
	computed: {
		dates: {
			get() {
				if(this.datepicker_value) {
					return {
						date1: moment(this.datepicker_fullvalue).format('ddd MMM DD YYYY'),
						dateISO1: moment(this.datepicker_fullvalue).toDate()
					}
				}else{
					return {
						date1: moment().format('ddd MMM DD YYYY'),
						dateISO1: moment().toDate()
					}
				}
			},
			set(val) {
				this.datepicker_value = moment(val.date1).format('ddd, MMM D')
				this.datepicker_fullvalue = moment(val.dateISO1).format()
			}
		}
	},
	
	watch: {
		'airline.selected.code': function (newVal, oldVal) {
			this.airline.selected.name = this.airlines[newVal.toUpperCase()].name;
		},
		'flight_direction': function(newVal, oldVal) {
			let airport = {
				arrival: this.airport.arrival,
				departure: this.airport.departure
			}
			this.airport.departure = airport.arrival
			this.airport.arrival = airport.departure
			
			let sessionAirport = {
				'airport': this.airport,
				'date': moment(this.datepicker_fullvalue).format('YYYY-MM-DD')
			}
			
			sessionStorage.setItem('airport', JSON.stringify(sessionAirport));
		},
		'$parent.searchboxEdit': function(newVal, oldVal){
			if(oldVal) {
				$('#flightstatus-hero h1.title').hide()
			}else{
				$('#flightstatus-hero h1.title').show()
			}
		}
	},
	
	mounted() {
		this.setDefault()
		if (sessionStorage.getItem('airport')) {
			let session = JSON.parse(sessionStorage.getItem('airport'))
			if(!this.airport.departure.code) {
				this.airport.departure = session.airport.departure
			}
			this.airport.arrival = session.airport.arrival
			this.flight_direction = 'departures'
			this.datepicker_value = moment(session.date).format('ddd, MMM D')
			this.datepicker_fullvalue = moment(session.date).format()
			if(this.tab === 'airport' && this.searchboxType != 'multi' && this.airport.departure.code && this.airport.arrival.code && this.datepicker_fullvalue) {
				this.$parent.searchboxEdit = false;
				this.search(true)
			}
		}
		if (sessionStorage.getItem('airline')) {
			let session = JSON.parse(sessionStorage.getItem('airline'))
			if(!this.airline.departure) {
				this.airline.selected = session.airline
			}
			this.datepicker_value = moment(session.date).format('ddd, MMM D')
			this.datepicker_fullvalue = moment(session.date).format()
			this.flight_number = session.flight_number
			this.$parent.searchboxEdit = false;
			if(this.tab === 'airline' && this.searchboxType != 'multi') {
				this.search(true)
			}
		}
	},
	
	methods: {
		selectTab(tab){
			this.tab = tab
			this.showErrors = false
		},
		
		setDepartureAirport(airport) {
			this.airport.departure = airport
		},
		
		setArrivalAirport(airport) {
			this.airport.arrival = airport
		},

		setDefault() {
			if (apr && apr.content && apr.content.content.content) {
				let content = apr.content.content.content
				if (content.iata) {
					this.airline.default.name = content.name
					this.airline.default.code = content.iata.toLowerCase()
					this.airline.selected.name = content.name
					this.airline.selected.code = content.iata.toLowerCase()
				}
				if (content.code) {
					let airport = _.get(window, 'apr.content.content.content');
					let data = {
						'type': _.toLower(_.get(airport, 'type')),
						'city': _.get(airport, 'city'),
						'name': _.get(airport, 'name'),
						'code': _.get(airport, 'code'),
						'latitude': _.get(airport, 'latitude'),
						'longitude': _.get(airport, 'longitude'),
					}
					this.airport.default = data;
					this.airport.departure = data;
				}
			}
		},
		
		search(onLoad = false) {
			let url = ''
			let params = {}
			
			if (this.tab === "airport") {
				
				let sessionAirport = {
					'airport': this.airport,
					'date': moment(this.datepicker_fullvalue).format('YYYY-MM-DD')
				}
				
				sessionStorage.setItem('airport', JSON.stringify(sessionAirport));

				// Validation
				if (!onLoad) {
					if (!this.airport.departure.code || !this.datepicker_fullvalue) {
						console.error('Problem with search.')
						this.showErrors = true;
						return
					}
					
					if(this.airport.departure.code != this.airport.default.code){
						window.location = `${apr.app_url}/flight-status/${this.airport.departure.code.toLowerCase()}`
						return
					}
				}
				
				url = `${apr.app_url}/flight-status/routes`
				params = {
					departureAirport: this.airport.departure.code,
					arrivalAirport: this.airport.arrival.code,
					datetime: moment(this.datepicker_fullvalue).format('YYYY-MM-DD HH:MM:SS')
				}
				
			} else if (this.tab === "airline") {
				
				let sessionAirline = {
					'airline': this.airline.selected,
					'date': this.datepicker_fullvalue,
					'flight_number': this.flight_number
				}
				
				sessionStorage.setItem('airline', JSON.stringify(sessionAirline));
				
				// Validation
				if (!onLoad) {
					if (!this.airline.selected || !this.datepicker_fullvalue || !this.flight_number) {
						console.error('Problem with search.')
						this.showErrors = true;
						return
					}
					
					if(this.airline.selected.code != this.airline.default.code){
						window.location = `${apr.app_url}/flight-status/${this.airline.selected.name.replace(/ +/g, '-').toLowerCase()}`
						return
					}
				}
				
				url = `${apr.app_url}/flight-status/${this.airline.selected.code.toLowerCase()}`
				params = {
					datetime: moment(this.datepicker_fullvalue).format('YYYY-MM-DD HH:mm:ss'),
					flight_number: this.flight_number,
				}
				
			}
			
			this.working = true;
			this.$parent.isLoading = true;
			this.$http.post(url, params).then((response) => {
					this.$parent.results = response.data
					this.$parent.isLoading = false;
					this.working = false
					if(response.data && response.data.arriving && response.data.departing) {
						if (!response.data.arriving && response.data.length > 0 || response.data.arriving.length > 0 || response.data.departing.length > 0) {
							this.$parent.searchboxEdit = false;
							// --- Commented out (Do we actually need this?) ---
							// if (!onLoad) {
							// 	setTimeout(() => {
							// 		$('html, body').animate({scrollTop: $('.results').position().top}, 'slow');
							// 	}, 300)
							// }
						}
					}
				}, (error) => {
					// --- Commented out (Do we actually need this?) ---
					// if (!onLoad) {
					// 	$('html, body').animate({scrollTop: $('.results').position().top}, 'slow');
					// }
					this.$root.$emit('flightstatus-loading', false);
					this.working = false
					console.error(error.body)
				}
			);
		},
		
		showDatepicker() {
			this.$refs['datepicker_' + this.variable].openModal()
		},
		
		formatDate(date) {
			return date ? window.moment(date).format('ddd, MMM D') : null;
		},
	}
}
