import Datepicker from "./../../components/datepicker";
import AlgoliaTypeahead from "./../../components/Searchbox/components/AlgoliaTypeahead";

Vue.component('page-help-receipt', {
    components: {AlgoliaTypeahead},
    mixins: [Datepicker],

    data() {
        return {
            type: 'online',
            email: null,
            fax: null,
            checkindate: null,
            airportcode: null,
            firstname: null,
            lastname: null,

            successMessage: null,
            errorMessage: null,
        }
    },

    mounted() {
        let $field = $(this.$el).find("input[name='checkindate']")
        this.initDatepicker($field, {startDate: false})

        this.initForm()
    },

    computed: {},

    watch: {},

    methods: {
        setDate(date, fieldName) {
            this.$set(this, fieldName, date)
        },

        setAirport(airport) {
            this.airportcode = airport.code;
        },

        initForm() {
            let self = this
            let requesting = false;
            let $form = $(this.$el).find("form")

            $form.parsley().on("form:submit", function () {
                $form.find("input").each(function () {
                    $(this).parsley().removeError('custom-error');
                });
                return false;

            }).on('form:success', function (formInstance) {

                if (requesting) {
                    return false;
                }
                requesting = true;
                self.successMessage = null
                self.errorMessage = null

                self.$http.post('/help/receipt', self.getParams()).then(response => {
                    if (response.data.redirect) {
                        window.location.href = response.data.redirect
                    } else {
                        self.successMessage = response.data.message
                    }

                    dataLayer.push({'event': 'GaEvent', 'EventCategory':'Guest', 'EventAction': 'Receipt copy', 'EventLabel': ''})

                }, response => {
                    let errors = response.body

                    for (var errorName in errors) {
                        if (errorName == 'errors') {
                            self.errorMessage = self.filters.formatMessage(errors[errorName])
                        }

                        let $field = $form.find(`input[name="${errorName}"]`);

                        if ($field.length) {
                            $field.parsley().addError('custom-error', {message: errors[errorName]});
                        }
                    }

                    requesting = false;
                });

                return false;
            });
        },

        getParams() {
            return {
                type: this.type,
                email: this.email,
                fax: this.fax,
                checkindate: this.checkindate,
                airportcode: this.airportcode,
                firstname: this.firstname,
                lastname: this.lastname,
            };
        }
    }
})
