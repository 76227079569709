<template>
	<div :class="'component-alert alert alert-dismissible col-12 m-0 text-center ' + passedAlertType" role="alert">
		<button @click="trackEvent('Hello bar closed')" type="button" class="close" data-dismiss="alert" aria-label="Close" v-if="showClose">
			<span aria-hidden="true">&times;</span>
		</button>
		<i :class="'fas fa-' + passedIconName"></i>
		<span v-if="showText" v-html="passedText"></span>
		<span v-if="showTextAlt" v-html="passedTextAlt"></span>
	</div>
</template>

<script>
export default {
	props: [ 'passedText', 'passedTextAlt', 'passedIconName', 'passedAlertType', 'showText', 'showTextAlt', 'showClose' ],

	methods: {
		trackEvent(msg) {
			dataLayer.push({'event': 'GaEvent', 'EventCategory': apr.event_category, 'EventAction': msg, 'EventLabel': ''});
		}
	}
}
</script>

<style lang="scss">
	.component-alert {
		.alert {
			position: relative;
			margin: 0;
			padding: 0.5rem 2rem;
			border: 0;
			border-radius: 0;
			font-size: 0.93rem;
			z-index: 4;

			.close {
				position: absolute;
				padding: .5rem 1rem 0.85rem;
				line-height: 1rem;
				opacity: 1;
			}

			.alert-info {
				background-color: #0A475A;
				color: white;
			}

			.alert-error {
				background-color: #D41818;
				color: white;
			}
		}
	}
</style>
