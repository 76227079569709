<template>
    <div class="datetime" v-if="showTwoLabels">
        <label class="date">
            <div class="heading mb-1">{{ fieldLabel[0] }}</div>
            <div class="input-container" ref="dateContainer"></div>
            <i class="apr-icon-calendar"></i>
            <input
                type="text"
                class="form-control"
                placeholder="Select Date"
                v-model="dateStr"
                :data-value="date"
                :data-calendar-label="calendarLabel"
                :name="variable"
                readonly
                required
                data-parsley-required-message="Please select date and time"
            />
        </label>
        <label class="time">
            <div class="heading mb-1">{{ fieldLabel[1] }}</div>
            <i class="apr-icon-time"></i>
            <select
                class="custom-select"
                required
                v-model="time"
                :name="variable"
                data-parsley-required-message="Please select date and time"
            >
                <option value="" disabled>Select Time</option>
                <option v-for="timeOption in timeOptions" :value="timeOption[0]">{{ timeOption[1] }}</option>
            </select>
        </label>
    </div>

    <div class="datetime" v-else>
        <label class="date d-flex flex-column">
            <div class="heading mb-1">{{ fieldLabel }}</div>
            <div class="input-container d-flex" ref="dateContainer">
                <div class="w-100">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Select Date"
                        v-model="dateStr"
                        :data-value="date"
                        :data-calendar-label="calendarLabel"
                        :name="variable"
                        readonly
                        required
                        data-parsley-required-message="Please select date and time"
                    />
                </div>
                <select
                    class="custom-select"
                    required
                    v-model="time"
                    :name="variable"
                    data-parsley-required-message="Please select date and time"
                >
                    <option value="" disabled>Select Time</option>
                    <option v-for="timeOption in timeOptions" :value="timeOption[0]">{{ timeOption[1] }}</option>
                </select>
            </div>
        </label>
    </div>
</template>

<script>
    export default {

        model: {
            prop: 'time',
            event: 'change'
        },

        props: {
            value: {
                type: String,
            },
            variable: {
                type: String,
                required: true,
            },
            showTwoLabels: {
                type: Boolean,
                default: false
            },
        },

        data() {
            return {
                // text
                fieldLabel: this.getDatetimeFieldLabel(),
                calendarLabel: this.getDatetimeCalendarLabel(),

                // dropdown values
                timeOptions: this.getTimeOptions(),

                // values
                date: this.getDatePart(),
                dateStr: this.getDateStrPart(),
                time: this.getTimePart()
            }
        },

        computed: {
            datetimeComputed() {
                if (this.date && this.time) {
                    return `${this.date} ${this.time}:00`
                }
                return null
            }
        },

        watch: {
            '$parent.$parent.shuttlefinder.trip_type': function(newValue, oldValue) {
                this.fieldLabel = this.getDatetimeFieldLabel()

                // update datetime field if trip type was changed
                if (newValue !== oldValue && oldValue !== null) {
                    this.updateStoreDateField()
                }
            },

            '$parent.$parent.shuttlefinder': {
                handler() {
                    this.calendarLabel = this.getDatetimeCalendarLabel()
                },
                deep: true
            },

            date() {
                this.$emit('change', this.date)
            },

            time() {
                this.$emit('change', this.time)
            },

            datetimeComputed() {
                if (this.value !== this.datetimeComputed) {
                    this.updateStoreDateField()
                }
            },

            value() {
                this.setNewTimeProperties()
            }
        },

        mounted() {
            setTimeout(() => {
                this.initDatepicker($(this.$el).find('input'))
            }, 100)
        },

        methods: {
            getTimeOptions() {
                let startTime = moment().utc().set({day: 1, hour: 24, minute: 0});
                let endTime = moment().utc().set({day: 2, hour: 23, minute: 59});
                let timeStops = [];

                while (startTime <= endTime) {
                    let time = moment(startTime)
                    timeStops.push([
                        time.format('HH:mm'),
                        time.format('h:mm A')
                    ]);
                    startTime.add(15, 'minutes');
                }

                return timeStops
            },

            getDatePart() {
                if (this.value) {
                    return moment(this.value).format('YYYY-MM-DD')
                }
                return ''
            },

            getDateStrPart() {
                if (this.value) {
                    return moment(this.value).format('ddd, MMM D')
                }
                return ''
            },

            getTimePart() {
                if (this.value) {
                    return moment(this.value).format('HH:mm')
                }
                return ''
            },

            setNewTimeProperties() {
                this.date = this.getDatePart();
                this.dateStr = this.getDateStrPart();
                this.time = this.getTimePart();
            },

            initDatepicker($field) {
                let self = this
                let options = {
                    format: 'ddd, MMM D YYYY',
                    startDate: moment().format('ddd, MMM D YYYY'),
                    autoClose: true,
                    singleDate: true,
                    duration: 5,
                    container: this.$refs.dateContainer,
                    setValue: function (s, date) {
                        self.date = moment(date, 'ddd, MMM D YYYY').format('YYYY-MM-DD')
                        self.dateStr = moment(date, 'ddd, MMM D YYYY').format('ddd, MMM D')
                        $('select[name="datetime"]').focus();
                    },
                };

                $field.dateRangePicker(options)
                    .bind('datepicker-open', function () {
                        if (self.variable === 'returnDatetime') {
                            // $field.data('dateRangePicker').setStart('Wed, Aug 14');
                        }
                    })
                    .bind('datepicker-opened', function () {
                        $(self.$el).addClass('active')
                    })
                    .bind('datepicker-closed', function () {
                        $(self.$el).removeClass('active')
                    });
            },

            updateStoreDateField() {
                // console.log(`update${this.getDatetimeFieldName(this.variable).capitalize()}`, this.datetimeComputed)
                this.$store.dispatch(`update${this.getDatetimeFieldName(this.variable).capitalize()}`, this.datetimeComputed)
            },

            getDatetimeFieldName(variable) {
                let tripType = this.$parent.$parent.shuttlefinder.trip_type

                if (variable === 'datetime') {
                    if (tripType !== 'nonAirport') {
                        return 'dropoff_date'
                    }
                    return 'pickup_date' // default, pointToPoint

                    // it means round trip
                } else if (variable === 'returnDatetime') {
                    if (tripType !== 'nonAirport') {
                        return 'return_dropoff_date'
                    }
                    return 'return_pickup_date'
                }
            },

            getDatetimeFieldLabel() {
                let tripType = this.$parent.$parent.shuttlefinder.trip_type
                let fieldLabel = ''

                if (this.variable === 'datetime') {
                    if (tripType === 'toAirport') {
                        fieldLabel = 'Flight Departure'
                    } else if (tripType === 'fromAirport') {
                        fieldLabel = 'Flight Arrival'
                    } else if (tripType === 'airportToAirport') {
                        fieldLabel = 'Flight Arrival'
                    } else {
                        fieldLabel = 'Pick Up' // default, pointToPoint
                    }

                    // it means round trip
                } else if (this.variable === 'returnDatetime') {
                    if (tripType === 'toAirport') {
                        fieldLabel = 'Return Flight Arrival'
                    } else if (tripType === 'fromAirport') {
                        fieldLabel = 'Return Flight Departure'
                    } else if (tripType === 'airportToAirport') {
                        fieldLabel = 'Return Flight Arrival'
                    } else {
                        fieldLabel = 'Return Pick Up'
                    }
                }

                if (this.showTwoLabels) {
                    return [`${fieldLabel} Date`, `${fieldLabel} Time`]
                }

                return `${fieldLabel} Date & Time`
            },

            getDatetimeInlineFieldLabel(variable) {
                let tripType = this.$parent.$parent.shuttlefinder.trip_type

                if (variable === 'datetime') {
                    if (tripType === 'toAirport') {
                        return 'Flight Departure Date & Time'
                    } else if (tripType === 'fromAirport') {
                        return 'Flight Arrival Date & Time'
                    } else if (tripType === 'airportToAirport') {
                        return 'Flight Arrival Date & Time'
                    } else {
                        return 'Pick Up Date & Time' // default, pointToPoint
                    }

                    // it means round trip
                } else if (variable === 'returnDatetime') {
                    if (tripType === 'toAirport') {
                        return 'Return Flight Arrival Date & Time'
                    } else if (tripType === 'fromAirport') {
                        return 'Return Flight Departure Date & Time'
                    } else if (tripType === 'airportToAirport') {
                        return 'Return Flight Arrival Date & Time'
                    } else {
                        return 'Return Pick Up Date & Time'
                    }
                }
            },

            getDatetimeCalendarLabel() {
                let tripType = _.get(this.$parent.$parent, 'shuttlefinder.trip_type')
                let airportCode1 = _.get(this.$parent.$parent, 'shuttlefinder.pickup.airport_code')
                let airportCode2 = _.get(this.$parent.$parent, 'shuttlefinder.dropoff.airport_code')

                if (this.variable === 'datetime') {
                    let defaultLabel = 'Select your pick up date'
                    if (tripType === 'toAirport') {
                        return airportCode2 ? `Select date your flight departs from ${airportCode2}` : defaultLabel
                    } else if (tripType === 'fromAirport') {
                        return airportCode1 ? `Select date your flight arrives at ${airportCode1}` : defaultLabel
                    } else if (tripType === 'airportToAirport') {
                        return airportCode2 ? `Select date your flight arrives at ${airportCode1}` : defaultLabel
                    } else {
                        return defaultLabel // default, pointToPoint
                    }

                    // it means round trip
                } else if (this.variable === 'returnDatetime') {
                    let defaultLabel = 'Select your return pick up date'

                    if (tripType === 'toAirport') {
                        return airportCode2 ? `Select date your return flight arrives at ${airportCode2}` : defaultLabel
                    } else if (tripType === 'fromAirport') {
                        return airportCode1 ? `Select date your return flight departs from ${airportCode1}` : defaultLabel
                    } else if (tripType === 'airportToAirport') {
                        return airportCode1 ? `Select date your return flight arrives at ${airportCode2}` : defaultLabel
                    } else {
                        return defaultLabel
                    }
                }
            },
        }
    }
</script>

<style lang="scss">
.input-container {
    position: relative;
    .date-picker-wrapper {
        top: -12px !important;
        left: 0 !important;
        min-width: 540px;

        .next i,
        .prev i {
            position: static !important;
            color: white !important;
        }

        .drp_top-bar {
            position: relative;
            padding: 1.2rem !important;
            background-color: white !important;
            border-bottom: 1px solid #c7d4dd;
            font-size: 0.95rem;
            font-weight: 400;
            line-height: 1rem;
            letter-spacing: normal;

            .normal-top,
            .error-top {
                display: none;
            }

            &.normal {
                .normal-top {
                    display: block;

                    span {
                        display: none;
                    }
                }

                .default-top {
                    display: none;
                }
            }
        }
    }
}
.datetime {
    display: flex;
    gap: 16px;
    & > .date,
    & > .time {
        flex: 0 0 calc(50% - 12px);
        position: relative;
    }
    .apr-icon-calendar,
    .apr-icon-time {
        position: absolute;
        left: 16px;
        top: 2.375rem;;
    }
    input,
    select {
        display: block;
        width: 100%;
        padding: 12px 16px 12px 48px !important;
    }
    select {
        background: none;
    }
    input {
        // font-family: $fontFamily;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #0e242b;
        border-radius: 8px;
        border: 1px solid #b7bdbf !important;
        background-color: #fff !important;

        // &:focus {
        //     border: 1px solid $brand-primary-500;
        //     outline: 2px solid $brand-primary-500;
        //     box-shadow: none;
        // }

        // &.is-invalid,
        // &.checkout-error,
        // &.parsley-error {
        //     border-color: $status-error-red-100;
        //     &:focus {
        //         outline: 2px solid $status-error-red-100;
        //     }
        // }
    }
}
</style>
