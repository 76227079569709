import DestinationMap from '../../components/DestinationMap'

Vue.component('page-shuttle-landing', {

    components: {DestinationMap},

    mixins: [],

    computed: {},

    watch: {

    },

    data: function () {
        return {
            airport: apr.content ? apr.content.content : '',
            packageName: null,
        }
    },

    mounted() {
		let self = this
		eventHub.$on('package_type', function(val){
			self.packageName = val
		})
    },

    methods: {
		changePackage(name){
      this.$store.dispatch('updatePackage', name);
      if (name === 'APR') {
        this.$store.dispatch('updateTab', 'parking')
      } else if (name === 'SF') {
        this.$store.dispatch('updateTab', 'shuttle')
      } else {
        this.$store.dispatch('updateTab', 'hotel')
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
		},
    }
});
