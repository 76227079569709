<template>
    <div id="searchbox" class="hotel">
        <div class="searchbox">

            <!-- FIELDS -->
            <form action="" method="post" autocomplete="off" ref="searchboxForm">
                <display-psf v-if="session.dates && !isEditMode"></display-psf>
                <option-psf v-else></option-psf>
            </form>
            <div class="text-danger p-3" v-if="errors">{{ errors.error }}</div>
        </div>
    </div>
</template>

<script>
    import SearchboxMixin from "./SearchboxMixin.js";
    import RequestMixin from "./Requests.js";

    import OptionPSF from "./options/OptionsPSF.vue";
    import DisplayPSF from "./display/DisplayPSF.vue";

    export default {
        mixins: [SearchboxMixin, RequestMixin],
        props: ['hotel'],
        components: {
            'option-psf': OptionPSF,
            'display-psf': DisplayPSF,
        },

        data() {
            return {
                type: 'searchbox-hotel',
                errors: null,

                isEditMode: false,
                isDefaultMode: false,
            }
        },

        mounted() {
            this.initSearchboxForm()
            this.initSearchboxTooltips()

            if (_.get(window, 'apr.psf_search_results.search')) {
                this.setData(_.get(window, 'apr.psf_search_results.search'))

            } else if (_.get(window, 'apr.hotel')) { // we don't have a search yet, so showing opened searchbox
                this.isEditMode = true;
                this.isDefaultMode = true;

                this.$store.dispatch('updateAirport', _.get(window, 'apr.hotel.airport'))
            }
        },

        methods: {
            setData(data) {
                let search = {
                    id: apr ? apr.token : '',
                    airport: {
                        type: apr.hotel ? apr.hotel.airport.type : data.airport.type,
                        code: apr.hotel ? apr.hotel.airport.code : data.airport.code,
                        name: apr.hotel ? apr.hotel.airport.name : data.airport.name,
                        city: apr.hotel ? apr.hotel.airport.city : data.airport.city,
                        state_code: apr.hotel ? apr.hotel.airport.state.code : data.airport.state.code,
                        latitude: apr.hotel ? apr.hotel.airport.latitude : data.airport.latitude,
                        longitude: apr.hotel ? apr.hotel.airport.longitude : data.airport.longitude,
                    },
                    date1: data.date1,
                    date2: data.date2,
                    dates: data.dates,
                    hotel_id: apr.hotel ? apr.hotel.hotel_id : 0,
                    package: data.package,
                    guests: data.guests,
                    rooms: data.rooms
                };
                let tab = this.passedOption == 'APR' ? 'parking' : 'hotel';
                this.$store.dispatch('updateTab', tab)
                this.$store.dispatch('updateSession', search);
            },

            setTab(tab) {
                if (tab == 'parking') {
                    this.$store.dispatch('updatePackage', 'APR');
                } else {
                    this.$store.dispatch('updatePackage', this.session.package);
                }
                this.$store.dispatch('updateTab', tab);
            },

            getPageHeading(tagName) {
                if (this.currentTab == 'hotel') {
                    return this.headings.psf[tagName]
                }
                if (this.currentTab == 'shuttle') {
                    return this.headings.sf[tagName]
                }
                return this.headings.apr[tagName]
            },

	        departureHeading(val){
		        const isCruiseport = val.indexOf('Port ') === 0 || val.indexOf('Cruise') >= 0 || val.indexOf('Cape ') === 0;
		        if (this.cruiseportPage) {
			        if (!isCruiseport && val) {
				        return 'airport'
			        }
			        return 'cruiseport'
		        } else {
			        if (isCruiseport) {
				        return 'cruiseport'
			        }
			        return 'airport'
		        }
	        },
        }
    };
</script>
