Vue.component('page-intermediate-psf', {
    data() {
        return {
            reservation: _.get(window, 'apr.reservation'),
        }
    },

    mounted() {
        this.processBooking()
    },

    methods: {
        processBooking() {
            this.$http.put(`/intermediate/psf/${this.reservation.id}`, {
                airport_code: _.get(window, 'apr.airport_code'),
                hotel: _.get(window, 'apr.hotel')

            }).then((response) => {
                let successResponse = _.get(response, 'body.data');
                let receiptUrl = _.get(successResponse, 'receipt_url');

                if (receiptUrl) {
                    window.location.href = receiptUrl;
                }
            }, (error) => {
                let errorResponse = _.get(error, 'body.data');
                let redirectUrl = _.get(errorResponse, 'redirect');

                // redirect back
                if (redirectUrl) {
                    window.location.href = redirectUrl;
                }
            })
        }
    }
});