Vue.component('seasonal',{
    data: function () {
        return {
            email: null,
            isSubscribed: false,
        }
    },

    methods: {
        subscription(e)
        {
            if(this.email !== null){
                this.$http.post('/newsletters/subscribe', {email: this.email}).then(response => {
                    this.isSubscribed = true;
                    this.email = null;
                });
            }
            e.preventDefault();
        }
    }
});