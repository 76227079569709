<template>
    <div class="options sf-options row m-0">
        <airport-dialog-box></airport-dialog-box>
        <div class="row col-12 m-0 p-0 mb-2">
            <algolia-google-typeahead-s-f
                title="Pick Up Location"
                variable="pickup"
                :is-shuttlefinder="true"
                class="col-12 col-xl p-0 mr-xl-3 mb-2"
            ></algolia-google-typeahead-s-f>
            <algolia-google-typeahead-s-f
                title="Drop Off Location"
                variable="dropoff"
                :is-shuttlefinder="true"
                class="col-12 col-xl p-0 mr-xl-3 mb-2"
            ></algolia-google-typeahead-s-f>

            <label class="col col-xl-1 pl-0 mb-2 pr-0 guests">
                <div class="heading mb-1">Passengers</div>
                <select name="guests" v-model="$parent.shuttlefinder.passengers">
                    <option :value="n" v-for="n in 16">{{ n }}</option>
                </select>
                <i class="fa fa-chevron-down input-icon"></i>
            </label>
        </div>

        <div class="row col-12 m-0 p-0">
            <div class="col p-0">
                <datetime-picker :value="datetime" variable="datetime" :showTwoLabels="true"></datetime-picker>
                <div class="form-check-label custom-control custom-switch apr-custom-switch">
                    <input type="checkbox" id="returnTripCheckbox" class="custom-control-input" v-model="isRoundTrip" />
                    <label for="returnTripCheckbox" class="custom-control-label">Book Return Trip?</label>
                </div>
                <datetime-picker
                    :value="returndatetime"
                    variable="returnDatetime"
                    v-if="isRoundTrip"
                    :showTwoLabels="true"
                ></datetime-picker>
            </div>
            <div class="col-12 col-xl-3 p-0 pl-3 my-3 my-xl-0 position-relative">
                <div class="d-none d-xl-block heading mb-1 invisible">Submit</div>
                <button type="submit" class="btn-brand-primary">
                    <span
                        v-if="$parent.inProgressSearch"
                        class="btn-brand-primary__loading-container"
                        aria-hidden="true"
                    >
                        <span class="btn-brand-primary__loading"></span>
                    </span>
                    <span v-else-if="$parent.type === 'searchbox'">
                        Find shuttle <i class="apr-arrow-right" aria-hidden="true"></i>
                    </span>
                </button>
                <!-- <div
                    class="powered-by text-white text-uppercase font-weight-lighter text-center position-absolute"
                    style="left: 1rem; right: 0; bottom: -1.45rem"
                >
                    <small
                        >Powered By
                        <a href="https://www.shuttlefinder.com/" rel="nofollow" class="text-primary font-weight-normal"
                            >Shuttlefinder</a
                        ></small
                    >
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
// import Datepicker from './../../datepicker'
import Datepicker from './../../datepicker'
import DatetimePicker from '../../../components/DatetimePicker'
import BootstrapToggle from '../../../components/BootstrapToggle'
import AlgoliaGoogleTypeaheadSF from '../components/AlgoliaGoogleTypeaheadSF'
import AirportDialogBox from '../components/_airportDialogBox'
import * as VueGoogleMaps from 'vue2-google-maps'

export default {
    mixins: [Datepicker],
    components: { AlgoliaGoogleTypeaheadSF, BootstrapToggle, DatetimePicker, AirportDialogBox },
    data() {
        return {
            isRoundTrip: false,
            datetime: '',
            returndatetime: '',
        }
    },

    watch: {
        isRoundTrip: function (val) {
            this.$store.dispatch('updateReturn', val)
        },
    },

    mounted() {
        Vue.use(VueGoogleMaps, { load: window.googleMapsConfig })
    },
}
</script>

<style></style>
