import DirectionSearch from '../components/DirectionSearch'

Vue.component('page-directions', {
	
	components: { DirectionSearch },
	
	data() {
		return {
			searchEdit: true,
			results: null,
		}
	},
	
	mounted() {
	},
	
	computed: {},
	
	watch: {},
	
	methods: {}
});
