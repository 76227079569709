Vue.component('modal-receipt', {
    components: {
    },

    data() {
        return {
			paperless: apr.paperless
        }
    },

    mounted() {
		if(!this.paperless){
			$('#Receipt_Modal').modal('show')
		}

        $('[data-toggle="popover"]').popover({
            html: true,
        })
    },

    methods: {
        toggleReceipt() {
			$('#Receipt_Modal').modal('toggle')
        }
    }
});
