<template>
	
	<section id="flightstatus-search" v-if="$parent.searchboxEdit">
		<div class="container">

			<!-- Title -->
			<h1 v-if="!headingOne">{{ airport.default.code ? airport.default.code : '' }} Airport - Live Flight Status & Delays</h1>
			<h1 v-if="headingOne">{{ headingOne }}</h1>
			<p class="text-center text-white mb-5">Use the form below to search for {{ airport.default.code }} <a :href="`/flight-status/${airport.default.code ? airport.default.code.toLowerCase() : ''}-arrivals`">arrivals</a> and <a :href="`/flight-status/${airport.default.code ? airport.default.code.toLowerCase() : ''}-departures`">departures</a></p>


			<div class="row m-0">

				<!-- Flight Departure Airport -->
				<div class="item airport col-12 col-lg-4 col-xl-3 px-0 py-2 py-xl-0">
					<label>Airport</label>
					<algolia-typeahead
							id="airportSelect"
							ref="algoliaTypeahead"
							placeholder="Enter Airport"
                            class="flightstatus-page-typeahead"
                            :airport="airport.departure"
                            v-on:typeahead-change="setDepartureAirport">
					</algolia-typeahead>
				</div>

				<!-- Flight Direction -->
				<div class="item flight-direction col-12 col-lg-4 col-xl px-0 pl-lg-3 py-2 py-xl-0">
					<label>Arrival or Departure</label>
					<div class="dropdown">
						<button class="btn dropdown-toggle" :class="{ 'null': !flight_direction }" type="button" id="FlightDirection" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span v-if="!flight_direction">Arriving or departing from {{ airport.default.code }}?</span>
							<span v-else-if="flight_direction === 'arrivals'">Arriving at {{ airport.departure.code ? airport.departure.code.toUpperCase() : '' }}</span>
							<span v-else-if="flight_direction === 'departures'">Departing from {{ airport.departure.code ? airport.departure.code.toUpperCase() : '' }}</span>
						</button>
						<div class="dropdown-menu" aria-labelledby="FlightDirection">
							<button class="dropdown-item" type="button" @click.prevent="flight_direction = 'arrivals'">Arriving at {{ airport.departure.code ? airport.departure.code.toUpperCase() : '-' }}</button>
							<button class="dropdown-item" type="button" @click.prevent="flight_direction = 'departures'">Departing at {{ airport.departure.code ? airport.departure.code.toUpperCase() : '-' }}</button>
						</div>
					</div>
				</div>

				<!-- Flight Arrival Airport -->
				<div class="item airport col-12 col-lg-4 col-xl px-0 pl-lg-3 py-2 py-xl-0">
					<label v-if="flight_direction === 'arrivals'">Departure Airport</label>
					<label v-else-if="flight_direction === 'departures'">Arrival Airport</label>
					<label v-else>Airport</label>
                    <algolia-typeahead
                            id="airportSelect2"
                            placeholder="Enter Airport"
                            class="flightstatus-page-typeahead"
                            :airport="airport.arrival"
                            v-on:typeahead-change="setArrivalAirport">
                    </algolia-typeahead>
				</div>

				<!-- Flight Departure Time -->
				<div class="item departure-date col-12 col-lg-6 col-xl px-0 pl-xl-3 py-2 py-xl-0">
					<label>Flight Departure Date</label>
					<div class="datepicker-container">
						<div class="datepicker-text">{{ datepicker_fullvalue ? formatDate(datepicker_fullvalue) : null }}</div>
						<datetimepicker class="departure-date h-auto"
										title="Departure"
										name="departureDate"
										autocomplete="off"
										autocorrect="off"
										autocapitalize="off"
										spellcheck="false"
										icon="fa-calendar"
										placeholder="Select flight departure date"
										variable="date2"
										ref="datepicker"
										v-model="datepicker_fullvalue"
										:config="datepicker_options">
						</datetimepicker>
					</div>
				</div>

				<!-- Submit -->
				<div class="item col-12 col-lg-6 col-xl px-0 pl-lg-3">
					<div class="submit px-0 mt-xl-4" style="margin-top: 2rem">
						<button type="submit" class="btn-brand-primary" @click.prevent="search(false)">
							Search
							<i class="apr-arrow-right" aria-hidden="true"></i>
							<span v-show="working" class="btn-brand-primary__loading-container" aria-hidden="true">
								<span class="btn-brand-primary__loading"></span>
							</span>
						</button>
					</div>
				</div>

			</div>
		</div>
	</section>

	<section id="flightstatus-view" v-else>
		<div class="container px-5">
			<h1>Flights {{ flight_direction != 'departures' ? 'from' : 'to' }} {{ airport[flight_direction === 'departures' ? 'departure' : 'arrival'].code.toUpperCase() }} {{ flight_direction === 'departures' ? 'from' : 'to' }} {{ airport[flight_direction === 'departures' ? 'arrival' : 'departure'].code.toUpperCase() }}</h1>
			<h4>
				Departure Date<br>
				<span class="font-weight-lighter">{{ formatDate(datepicker_value) }}</span>
			</h4>
			<a href="#" @click.prevent="$parent.searchboxEdit = true">Edit Search</a>
		</div>
	</section>

</template>
<script>
	import FlightStatusMixin from '../../../../shared/js/FlightStatusMixin'
    import AlgoliaTypeahead from '../../components/Searchbox/components/AlgoliaTypeahead'
	import Datetimepicker from 'vue-bootstrap-datetimepicker'

	export default {
		mixins: [ FlightStatusMixin ],
		components: { AlgoliaTypeahead, Datetimepicker },

		props: {
			headingOne: { type: String, required: false },
			headingTwo:{ type: String, required: false },
		},

        data() {
            return {
                tab: 'airport',
            }
        },

        mounted() {
		    if (this.airport.departure.code) {
		        this.$refs.algoliaTypeahead.preSelectAirport();
            }
        },
	}
</script>
