<template>
    <label class="typeahead-component" :class="[variable + '-label']" @click.prevent>
        <div class="heading mb-1">{{ title }}</div>
        <div class="input-group input-group-lg w-100">
            <input type="text"
                   :id="variable"
                   :name="variable"
                   :class="[variable, 'form-control', hasItems ? 'has-items' : '']"
                   :placeholder="placeholder"
				   autocomplete="off"
				   autocorrect="off"
				   autocapitalize="off"
				   spellcheck="false"
                   v-model="query"
                   @keydown.down="down"
                   @keydown.up="up"
                   @keydown.enter.prevent="hit"
                   @keydown.esc="reset"
                   @mouseenter="isHovered = true"
                   @mouseleave="isHovered = false"
                   @input="update"
                   @focus="focus"
                   required
                   data-parsley-required-message="Please enter an address or airport."
            />

            <div class="input-group-append">
                <i class="fas fa-map-marker-alt typeahead-input-icon" v-if="!query"></i>
                <i class="fas fa-times typeahead-input-icon" v-if="query" @click="reset"></i>
            </div>
        </div>

        <div class="typeahead-list-sf" v-if="hasItems">
            <template v-for="groupItems in itemsByGroup">
                <div class="typeahead-list-category">{{ groupItems.type }}</div>
                <div class="typeahead-list-item" v-for="item in groupItems.items" :class="activeClass(item.index)"
                     @mousedown="hit" @mousemove="setActive(item.index)" @mouseover="setActive(item.index)">

                    <div class="dd-icon-container">
                        <i :class="getIconClass(item)"></i>
                    </div>
                    <span v-html="getNameForDropdown(item)"></span>
                </div>
            </template>
            <!--<div v-if="poweredByGoogle" class="text-right font-weight-bold p-2">-->
                <!--<div class="powered-by-google"></div>-->
            <!--</div>-->
        </div>
    </label>
</template>

<script>
    let algoliasearch = require('algoliasearch')
    import VueTypeahead from 'vue-typeahead'
    import * as VueGoogleMaps from 'vue2-google-maps'

    export default {
        extends: VueTypeahead,

        props: {
            variable: {
                type: String,
                required: true
            },
            type: {
                type: String,
                default: 'all'
            },
            hitsPerPage: {
                type: Number,
                default: 6
            },
            title: {
                type: String,
                required: true
            },
            placeholder: {
                type: String,
                default: 'Enter airport, place, or address'
            },
	        isShuttlefinder: {
            	type: Boolean,
				default: false
			}
        },

        data() {
            return {
                el: null,

                items: [],
                query: '',
                current: -1,

                algolia: null,

                apiService: null,
                apiSessionToken: null,

                isFocused: false,
                isHovered: false,
                poweredByGoogle: false,
            }
        },

        mounted() {
            let self = this
            this.el = $(`#${this.id}`)

            // algolia
            let client = algoliasearch(apr.algolia_id, apr.algolia_key)
            this.algolia = client.initIndex(apr.algolia_index_sf)

            // google
            VueGoogleMaps.loaded.then(() => {
                this.apiService = new google.maps.places.AutocompleteService()
                this.apiSessionToken = new google.maps.places.AutocompleteSessionToken()
            })

            // pre-selected
            if (this.isShuttlefinder) {
                this.query = this.formatQuery(this.$parent.$parent.shuttlefinder[this.variable])
            } else {
                this.query = this.formatQuery(this.$parent[this.variable]);
            }
        },

        computed: {
            itemsByGroup() {
                let index = 0
                return _.chain(this.items)
                    .groupBy(x => x.type)
                    .map((values, key) => ({ // group and adding index to each item
                        type: key,
                        items: _.chain(values)
                            .map((value, key) => {
                                value.index = index++
                                return value
                            })
                            .value()
                    }))
                    .value();
            },
        },

        watch: {
            '$parent.$parent.shuttlefinder': {
                handler() {
                    // set from session
                    if (this.isShuttlefinder) {
                        this.query = this.formatQuery(this.$parent.$parent.shuttlefinder[this.variable]);
                    }
                },
                deep: true
            },

            'items': function (items) {
                if (!this.items.length) {
                    this.poweredByGoogle = false
                } else if (items && _.findIndex(items, function (o) {
                    return o.provider === 'google'
                }) !== -1) {
                    this.poweredByGoogle = true
                } else {
                    this.poweredByGoogle = false
                }
            },
        },

        methods: {
            update: _.debounce(function () {
                if (!this.query) {
                    return this.reset()
                }

                if (this.query.length < 3) {
                    return
                }

                this.fetchFromAll()
            }, 100),

            fetchFromAll() {
                this.fetchAlgolia(this.query)
            },

            fetchAlgolia(searchQuery) {
                let self = this

                let options = {
                    query: searchQuery,
                    hitsPerPage: this.hitsPerPage,
                }

                if (this.isOnlyAirports()) {
                    options.filters = '(type:airport OR type:cruiseport) AND active:true AND (hierarchy.country.code:US OR hierarchy.country.code:CA)'
                }

                // if (this.isOnlyAddress()) {
                //     options.filters = '(NOT type:airport AND NOT type:cruiseport) AND (hierarchy.country.code:US OR hierarchy.country.code:CA)'
                // }

                this.algolia.search(options).then(content => {
                    if (this.isOnlyAirports()) {
                        if (content.nbHits === 0) {
                            self.items = []
                        } else {
                            self.items = content.hits
                        }

                    } else {
                        self.fetchGooglePredictions(searchQuery, content.hits)
                    }
                })
            },

            isOnlyAirports() {
                return (this.type === 'airport')
            },

            isOnlyAddress() {
                return (this.type === 'address')
            },

            fetchGooglePredictions(searchQuery, alreadyFoundAlgoliaItems) {
                let self = this

                this.apiService.getPlacePredictions({
                    // types: ['(cities)'],
                    input: searchQuery,
                    componentRestrictions: {country: 'us'},
                    sessionToken: this.apiSessionToken

                }, function (predictions, status) {
                    self.resetSoft()

                    let items = alreadyFoundAlgoliaItems
                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                        predictions.forEach(function (prediction) {
                            items.push({
                                'types': prediction.types,
                                'type': self.getTypeFromPrediction(prediction.types),
                                'provider': 'google',
                                'description': prediction.description,
                                'place_id': prediction.place_id,
                            })
                        })
                    }

                    if (searchQuery === self.query) {
                        items = self.excludeDuplicatesFromResults(items)

                        self.items = items

                    } else {
                        self.fetchFromAll()
                    }

                    self.current = -1
                })
            },

            getNameForDropdown(item) {
                if (item.provider === 'google') { // google
                    return item.description
                }

                // return item.display.short // algolia
                return item._highlightResult.display.short.value
            },

            getTypeFromPrediction(types) {
                if (_.indexOf(types, 'transit_station') !== -1) {
                    return 'address'
                }
                if (_.indexOf(types, 'point_of_interest') !== -1 || _.indexOf(types, 'natural_feature') !== -1) {
                    return 'point of interest'
                }
                if (_.indexOf(types, 'establishment') !== -1) {
                    return 'place'
                }
                if (_.indexOf(types, 'locality') !== -1) {
                    return 'city'
                }
                if (_.indexOf(types, 'route') !== -1) {
                    return 'address'
                }
                return 'address'
            },

            getIconClass(result) {
                return {
                    'fas fa-plane': result.type === 'airport',
                    'fas fa-ship': result.type === 'cruiseport',
                    'fas fa-city': result.type === 'city',
                    'fas fa-map-marker-alt': result.type === 'address' || result.type === 'place',
                    'fas fa-landmark': result.type === 'landmark' || result.type === 'point of interest',
                }
            },

            excludeDuplicatesFromResults(items) {
                return _.chain(items)
                    .uniqBy('place_id')
                    .value()
            },

            onHit(item) {
                this.items = []

                if (item.provider === 'google') { // address
                    this.query = _.get(item, 'description')

                    this.setLocationInStore({
                        place_id: _.get(item, 'place_id', null),
                        name: this.query,
                        formatted_address: _.get(item, 'description', null),
                        type: 'address',
                        airport_code: null,
                    })

                } else if (_.indexOf(['airport', 'cruiseport'], item.type) !== -1) { // airport & cruiseport
                    this.query = item.display.full

                    this.setLocationInStore({
                        place_id: item.place_id,
                        name: this.query,
                        formatted_address: item.formatted_address,
                        type: item.type,
                        airport_code: _.get(item, 'hierarchy.airport_code', null),
                    })
                }

                this.$store.dispatch('updateTripType');

                this.$root.$emit('searchbox::place-selected', {
                    place_id: _.get(item, 'place_id'),
                    type: _.get(item, 'type'),
                    variable: this.variable,
                });

                this.emit();

                $('[name=datetimeDate]').focus()
            },

            reset() {
                this.resetSoft()
                this.query = ''

                this.$store.dispatch('update' + this.variable.capitalize(), {
                    place_id: null,
                    name: null,
                    formatted_address: null,
                    type: null,
                    airport_code: null,
                })

                $(this.$el).find('input').focus()
            },

            resetSoft() {
                this.items = []
            },

            focus() {
                $(this.$el).find('.input-group').removeClass('is-invalid');
            },

            blur() {
                this.resetSoft()
            },

            emit() {
	            if(this.isShuttlefinder) {
		            this.$emit('input', this.$parent.$parent.shuttlefinder[this.variable].name)
	            }else{
		            this.$emit('input', this.$parent[this.variable])
	            }
            },

            setLocationInStore(address) {
	            if(this.isShuttlefinder) {
		            this.$store.dispatch('update' + this.variable.capitalize(), address)
	            }else{
		            this.$parent[this.variable] = address;
	            }
            },

            formatQuery(item) {
                return item ? this.getFormattedResult(item) : ''
            },

            getFormattedResult(item) {
                if (_.indexOf(['airport', 'cruiseport'], item.type) !== -1) {
                    return item.name || ''
                }

                if (_.startsWith(item.formatted_address, item.name)) {
                    return item.formatted_address
                }

                if (item.formatted_address) {
                    return `${item.name}, ${item.formatted_address}`
                }
            },
        }
    }
</script>
