<template>
    <div class="options row m-0 flex-xl-nowrap">
        <label class="col-12 col-xl-3 p-0 mr-xl-3 mb-2" v-if="$parent.type != 'searchbox-hotel'">
			<div class="heading mb-1">Departure {{ $parent.isCruiseport ? 'Cruise Port' : 'Airport' }}</div>
            <algolia-typeahead
                    id="searchbox-airport"
                    name="searchbox-airport"
                    ref="algoliaTypeahead"
                    :airport="query.airport"
                    :placeholder="$parent.isCruiseport ? 'Enter departure cruise port' : 'Enter departure airport'">
            </algolia-typeahead>
        </label>

        <label class="col-12 col-xl-3 p-0 mr-xl-3 mb-2 sleep">
            <div class="heading mb-1 text-nowrap" v-if="$parent.type != 'searchbox-hotel'">When do you want to sleep at the hotel?</div>
            <div class="heading mb-1" v-else>Sleep Which Days?</div>
            <div class="dropdown_container" tabindex="0" @blur="sleepOptionOpen = false">
                <div class="dropdown_input" @click.prevent="sleepOptionOpen = !sleepOptionOpen">
                    <input type="hidden" name="which_nights" v-model="$parent.selectedOption">
                    <span v-if="$parent.selectedOption == 'PSF'">At the beginning of trip</span>
                    <span v-else-if="$parent.selectedOption == 'PFS'">At the end of trip</span>
                    <span v-else-if="$parent.type == 'searchbox-hotel' && $parent.selectedOption == 'PSFS'">Beginning & end of trip</span>
                    <span v-else-if="$parent.selectedOption == 'PSFS'">Both beginning & end of trip</span>
                    <span v-else-if="$parent.type != 'searchbox-hotel'" style="opacity: 0.75">Choose which night to sleep</span>
                    <span v-else style="opacity: 0.75">Choose which nights</span>
                    <ul class="parsley-errors-list" style="margin-top: 1.6rem;"><li class="parsley-required" style="line-height: 1rem;">Please select an option.</li></ul>
                </div>
                <div class="dropdown sleep-option" v-show="sleepOptionOpen">
                    <div class="dropdown_item" :class="{ 'active': query.package == 'PSF' }" @click.prevent="selectSleepOption('PSF')">At the beginning of my trip (the night before my flight)</div>
                    <div class="dropdown_item" :class="{ 'active': query.package == 'PFS' }" @click.prevent="selectSleepOption('PFS')">At the end of my trip (the night when I get back from my trip)</div>
                    <div class="dropdown_item" :class="{ 'active': query.package == 'PSFS' }" @click.prevent="selectSleepOption('PSFS')">Both at the beginning & end of trip (the night before my flight & the night I arrive back)</div>
                </div>
            </div>
            <i class="fa fa-chevron-down input-icon"></i>
        </label>

        <div class="col-12 col-xl-4 p-0 row m-0 mb-2 dates">
            <label class="date_input">
                <div class="heading text-nowrap mb-1">{{ getFieldTitle(getPackageType(), 0) }}</div>
                <span class="date-text" :class="{ 'date-text--placeholder': !query.date1 }">{{ query.date1 ? $parent.formatDate(query.date1) : 'Select Date' }}</span>
                <input type="text" @blur="dateChanged('checkin')" name="checkindate" v-model="query.date1" data-field="date1" placeholder="Select Date"
                       data-parsley-required-message="Please select a date." data-toggle="tooltip" :data-original-title="getFieldTooltip(getPackageType(), 0)" readonly required>
                <i class="apr-icon-calendar input-icon mr-3"></i>
            </label>
            <label class="date_input">
                <div class="heading text-nowrap mb-1">{{ getFieldTitle(getPackageType(), 1) }}</div>
                <span class="date-text" :class="{ 'date-text--placeholder': !query.date2 }">{{ query.date2 ? $parent.formatDate(query.date2) : 'Select Date' }}</span>
                <input type="text" @blur="dateChanged('checkout')" name="checkoutdate" v-model="query.date2" data-field="date2" placeholder="Select Date"
                       data-parsley-required-message="Please select a date." data-toggle="tooltip" :data-original-title="getFieldTooltip(getPackageType(), 1)" readonly required>
                <i class="apr-icon-calendar input-icon"></i>
            </label>
        </div>

        <label class="col-12 col-xl-1 pl-0 pl-xl-3 mb-2 pr-0 guests">
            <div class="heading mb-1">Guests</div>
            <select name="guests" v-model="query.guests">
                <option :value="n" v-for="n in 16">{{ n }}</option>
            </select>
            <i class="fa fa-chevron-down input-icon"></i>
        </label>

        <label class="col-6 col-xl-1 pr-0 mb-2 guests" v-if="$parent.type != 'searchbox'">
            <div class="heading mb-1">Rooms</div>
            <select name="guests" v-model="query.rooms">
                <option :value="n" v-for="n in 6">{{ n }}</option>
            </select>
            <i class="fa fa-chevron-down input-icon"></i>
        </label>

        <div class="col-12 col-xl p-0 pl-xl-3 my-3 my-xl-0">
            <div class="d-none d-xl-block heading mb-1 invisible">Submit</div>
            <button type="submit" class="btn-brand-primary" :style="{'min-width': $parent.type != 'searchbox-inline' ? '100px' : '180px' }">
                <span v-if="!$parent.inProgressSearch && $parent.type === 'searchbox'">SEARCH</span>
                <span v-if="!$parent.inProgressSearch && $parent.type === 'searchbox-inline'" style="white-space: nowrap">UPDATE SEARCH</span>
                <span v-if="!$parent.inProgressSearch && $parent.type === 'searchbox-hotel'" style="white-space: nowrap">UPDATE</span>
                <i v-if="!$parent.inProgressSearch && $parent.type != 'searchbox-hotel'" class="apr-arrow-right" aria-hidden="true"></i>
                <span v-if="$parent.inProgressSearch" class="btn-brand-primary__loading-container" aria-hidden="true">
                  <span class="btn-brand-primary__loading"></span>
                </span>
            </button>
            <span class="cancel" v-if="$parent.isEditMode && !$parent.isDefaultMode" :style="{'min-width': $parent.type != 'searchbox-inline' ? '100px' : '180px' }">
                <a href="#" @click.prevent="$parent.isEditMode = false; $parent.setTab($parent.sessionTab);" class="text-link">Cancel</a>
            </span>
        </div>
    </div>
</template>

<script>
    import AlgoliaTypeahead from './../components/AlgoliaTypeahead'
    import Datepicker from './../../datepicker'
    import { mapGetters, mapActions } from 'vuex'

    export default {
        mixins: [Datepicker],
        components: {AlgoliaTypeahead},

        data() {
            return {
                sleepOptionOpen: false,
            }
        },

        mounted() {
            if (this.isTouchDevice() === false) {
                $('[data-toggle="tooltip"]').tooltip();
            }
            this.$parent.initSearchboxDatepickers($(this.$el), {productType: 'psf'})

            if (_.get(window, 'apr.search') || _.get(window, 'apr.search_psf') || _.get(window, 'apr.psf_search_results.search')) {
                this.$parent.selectedOption = this.$parent.query.package
            }
        },

        computed: {
            ...mapGetters([
                'session', 'query',
            ]),
        },

        methods: {
            isTouchDevice() {
                return true == ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch);
            },

            getFieldTitle(_package, _index) {
                if (! _package) { _package = 'PSF' }
                return this.$parent.titles[_package].dates[_index]
            },

            getFieldTooltip(_package, _index) {
                if (! _package) { _package = 'PSF' }
                return this.$parent.titles[_package].tooltips[_index]
            },

            getPackageType() {
                return this.$parent.query.package
            },

            selectSleepOption(option) {
                this.$parent.selectedOption = option;
                this.$store.dispatch('updatePackage', option)
                setTimeout(() => {
                    this.sleepOptionOpen = false;
                    $('.sleep .dropdown_container').blur()
                    $('.sleep .dropdown_container').removeClass('error')
                }, 100)
            },

            dateChanged(when) {
                if (when === 'checkin') {
                    this.triggerSearchboxEvent(apr.event_category,`Check-in date entered ${this.$parent.currentTab}`)
                } else {
                    this.triggerSearchboxEvent(apr.event_category,`Check-out date entered ${this.$parent.currentTab}`)
                }
            },

            triggerSearchboxEvent(action, label = '') {
                dataLayer.push({
                    'event': 'GaEvent',
                    'EventCategory': apr.event_category,
                    'EventAction': action,
                    'EventLabel': label
                });
            },
        }
    };
</script>
