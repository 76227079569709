export default {
    methods: {
        getSearchParams() {
            return {
                airport: this.query.airport.code,
                package: this.query.package,
                date1: this.query.date1,
                date2: this.query.date2,
                guests: this.query.guests,
                rooms: this.query.rooms,
                hotel_id: apr.hotel ? apr.hotel.hotel_id : 0,
                checkindate: this.query.date1,
                checkoutdate: this.query.date2,
            };
        },
        getSearchParamsSF() {
            return {
                trip_type: this.shuttlefinder.trip_type,
                dropoff: { place_id: this.shuttlefinder.dropoff.place_id },
                dropoff_date: this.shuttlefinder.dropoff_date,
                pickup: { place_id: this.shuttlefinder.pickup.place_id },
                pickup_date: this.shuttlefinder.pickup_date,
                return_pickup_date: this.shuttlefinder.trip_type !== 'nonAirport' ? this.shuttlefinder.return_dropoff_date : this.shuttlefinder.return_pickup_date,
                return_dropoff_date: this.shuttlefinder.trip_type !== 'nonAirport' ? this.shuttlefinder.return_dropoff_date : this.shuttlefinder.return_pickup_date,
                passengers: this.shuttlefinder.passengers,
                return: this.shuttlefinder.return,
                referred_by: '10',
            }
        },

        getSearchUrl() {
            if (this.currentTab === 'parking') {
                return '/search'
            }
            if (this.currentTab === 'hotel') {
                return '/psf_search/airport'
            }
            if (this.currentTab === 'shuttle') {
                return apr.sf_search_in_airport_url
            }
        },

        getParams() {
            if (this.currentTab === 'parking') {
                return this.getSearchParams()
            }
            if (this.currentTab === 'hotel') {
                return this.getSearchParams()
            }
            if (this.currentTab === 'shuttle') {
                return this.getSearchParamsSF()
            }
        },

        initSearchboxForm() {
            let self = this
            let requesting = false;
            self.inProgressSearch = false;

            this.form.parsley().on('form:validate', function () {
                if (self.query.package == null) {
                    $('.sleep .dropdown_container').addClass('error')
                } else {
                    $('.sleep .dropdown_container').removeClass('error')
                }

            }).on("form:submit", function () {
                return false;

            }).on('form:success', function (formInstance) {
                self.form.parsley().reset();

                if (self.query.package === null) {
                    console.error('Package is Null');
                    return false;
                }

                if (self.inProgressSearch) {
                    return false;
                }

                self.inProgressSearch = true;
                self.errors = null;

                // ??
                if (self.query.package === 'PSF' && self.query.package === 'PFS' && self.query.package === 'PSFS') {
                    self.triggerSearchboxEvent(apr.event_category, `Searched ${self.query.package}`, '')
                } else {
                    self.triggerSearchboxEvent(apr.event_category, `Searched ${self.query.package}`, '')
                }

                if (self.type === 'searchbox-hotel') {
                    self.$http.post(_.get(window, 'apr.psf_search_in_hotel_url'), self.getSearchParams()).then(response => {
                        self.inProgressSearch = false;
                        self.$parent.inProgressSearch = false;
                        self.isEditMode = false;

                        self.$root.$emit('hotel-package-search', response);

                        let dataResponse = _.get(response, 'body.data');
                        if (_.get(dataResponse, 'search')) {
                            self.$store.dispatch('updateSession', _.get(dataResponse, 'search'));
                        }

                        self.$store.dispatch('updateSession', dataResponse.search);
                        self.$root.$emit('hotel-search-received', dataResponse);

                    }, response => {
                        let body = response.body
                        if (self.hotel && self.token) {
                            window.location.href = `/psf_search/${self.hotel.airport.code}?soldout=true&token=${self.token}&airport=&hotel=${self.hotel.name}`
                        }
                        requesting = false;
                        self.inProgressSearch = false;
                        self.$parent.inProgressSearch = false;
                        self.$root.$emit('hotel-package-search-failed', response)

                        let errorResponse = _.get(response, 'body.data');
                        if (_.get(errorResponse, 'search')) {
                            self.$store.dispatch('updateSession', _.get(errorResponse, 'search'));
                        }
                    });

                } else {
                    self.$http.post(self.getSearchUrl(), self.getParams()).then(response => {
                        if (response.data.redirect) {
                            window.location.href = response.data.redirect
                        } else if (response.data.data.redirect_url) {
                            window.location.href = response.data.data.redirect_url
                        }
                        setTimeout(() => {
                            self.inProgressSearch = false;
                        }, 7500)
                    }, response => {
                        let errors = response.body

                        if (_.get(response, 'body.errors')) {
                            errors = _.get(response, 'body.errors')
                        }

                        for (var errorName in errors) {
                            let $field = self.form.find(`[name="${errorName}"]`);

                            if ($field.length) {
                                $field.parsley().addError('custom-error', {message: errors[errorName]});
                            }
                        }

                        self.inProgressSearch = false;
                    });
                }

                return false;
            });
        },
    }
}
