<template>
    <div class="options row m-0">
        <label class="col-12 col-xl-3 p-0 mr-xl-3 mb-2" v-if="$parent.type != 'searchbox-hotel'">
            <div class="heading mb-1">Departure {{ $parent.isCruiseport ? 'Cruise Port' : 'Airport' }}</div>
            <div class="placeholder placeholder-airport"></div>
            <i class="fas fa-map-marker-alt input-icon"></i>
        </label>

        <div class="col p-0 row m-0 mb-2 dates">
            <label class="col-sm-6 p-0 pr-sm-3 date_input">
                <div class="heading mb-1">{{ titles['APR']['dates'][0] }}</div>
                <div class="placeholder"></div>
                <i class="apr-icon-calendar input-icon mr-3"></i>
            </label>
            <label class="col-sm-6 p-0 pl-sm-0 date_input">
                <div class="heading mb-1">{{ titles['APR']['dates'][1] }}</div>
                <div class="placeholder"></div>
                <i class="apr-icon-calendar input-icon"></i>
            </label>
        </div>

        <div class="col-12 col-xl-2 p-0 pl-xl-3 my-3 my-xl-0">
            <div class="d-none d-xl-block heading mb-1 invisible">Submit</div>
            <button type="submit" class="btn-brand-primary" disabled>
                <i class="apr-arrow-right" aria-hidden="true"></i>
                <span>SEARCH</span>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                titles: this.$parent.titles,
            }
        },
    };
</script>
