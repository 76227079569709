<template>
	<section>
		<div class="container">
			<div class="row">
				<div class="col-4 hidden-sm-down pl-0">
					<div class="category" v-for="category in list"
					:class="{ active: category_selected == category.name }" @click="selected_category(category)">{{
						category.name }} <i class="fas fa-chevron-right"></i></div>
					</div>
					<div class="col-8 row question-answer-section" v-for="category in list"
					v-if="category.name == category_selected">
					<div class="col-12">
						<div class="question-answer row" v-for="question in category.data"
						@click="selected_question(question)" :id="question.id">
						<div class="col-12 question">
							<span>
								<i class="fa fa-plus-circle" v-if="question.id != question_selected"></i>
								<i class="fas fa-minus-circle" v-if="question.id == question_selected"></i>
								{{ question.question }}
							</span>
						</div>

						<transition name="slide-fade">
							<div class="col-12 answer" v-if="question.id == question_selected"
							v-html="question.answer"></div>
						</transition>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
</template>

<script>
export default {
	props: {
		list: {
			type: Array,
			required: true
		},
		active: {}
	},
	data() {
		return {
			category_selected: "Billing",
			question_selected: '',
		}
	},
	mounted() {},

	watch: {
		active: function (newActive) {
			this.category_selected = newActive.category
			this.question_selected = newActive.question_id
			setTimeout(() => {
				var top = document.getElementById(newActive.question_id.toString()).offsetTop;
				window.scrollTo(0, top);
			}, 250)
		}
	},
	methods: {
		selected_category(category) {
			this.category_selected = category.name
			this.triggerClickEvent('Category', category.name)
		},

		selected_question(question) {
			if (this.question_selected != question.id) {
				this.question_selected = question.id
			} else {
				this.question_selected = ''
			}

			this.triggerClickEvent('Question', question.question)
		},

		triggerClickEvent(action, label) {
			let type = this.$parent.questionsType !== undefined ? this.$parent.questionsType : '';

			dataLayer.push({
				'event': 'GaEvent',
				'EventCategory': type + ' FAQ',
				'EventAction': action,
				'EventLabel': label
			})
		}
	}
}
</script>

<style scoped>
	.answer{
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
		overflow: hidden;
	}
	.slide-fade-enter-active{
		transition: all 0.3s ease;
		display: block;
		height: 100%;
	}
		.slide-fade-enter, .slide-fade-leave-active{
		opacity: 0;
		height: 0;
		border: 0;
	}
</style>
