import ldClientWrapper from "./launch-darkly-client";

export default {
    install(Vue) {
        ldClientWrapper.initialize();
        Vue.prototype.$ldClient = ldClientWrapper;

        window.addEventListener('beforeunload', (e) => {
            ldClientWrapper.close();
        });
    },
};
