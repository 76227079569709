export default {
    methods: {
	    formatDate(date, tz) {
		    if (tz) {
			    return date ? window.moment.utc(date).tz(tz).format('DD-MMM-YYYY') : null;
		    }
		    return date ? window.moment(date).format('DD-MMM-YYYY') : null;
	    },
	    
	    formatFriendlyDate(date, tz) {
		    if (tz) {
			    return date ? window.moment.utc(date).tz(tz).format('ddd, MMM D') : null;
		    }
		    return date ? window.moment(date).format('ddd, MMM D') : null;
	    },
	
	    formatTime(time, tz, withTZ) {
		    if (time) {
			    if (!tz) {
				    withTZ = undefined;
				    time = window.moment(time)
			    } else {
				    time = window.moment.utc(time).tz(tz);
			    }
			    if (withTZ) {
				    return time.format('hh:mm a')+' '+this.getShortTZ(tz);
			    }
			    return time.format('hh:mm a');
		    }
		    return null;
	    },

        getShortTZ(tz) {
            let stz = moment.tz(tz).format('z');
            if (isNaN(parseInt(stz))) {
                return stz;
            }
            return tz;
        },

        formatDuration(time) {
            let hours = Math.floor(time / 60) + 'h';
            let minutes = Math.ceil(time - (Math.floor(time / 60) * 60)) + 'm';
            if(hours === '0h') { hours = '' }
            return hours + ' ' + minutes;
        }
    }
}