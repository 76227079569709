Vue.component('page-password-reset', {
    data: function () {
        return {
            token: apr.token,
            email: null,
            password: null,
            password_confirmation: null,

            successMessage: null,
            isRequesting: false
        }
    },

    mounted() {
        this.initFormSubmit();
    },

    methods: {
        initFormSubmit() {
            let self = this;
            let $form = $(this.$el).find("form");

            $form.parsley().on("form:submit", function () {
                return false;

            }).on("form:validate", function () {
                $form.find(".parsley-custom-error").remove();

            }).on('form:success', function () {
                self.$http.post($form.attr("action"), self.getFormData()).then(response => {

                }, response => {
                    let redirect = _.get(response, 'body.data.redirect');
                    if (redirect) {
                        window.location.href = redirect;
                    }

                    let errors = _.get(response, 'body.errors', _.get(response, 'body'));
                    for (var errorName in errors) {
                        let $field = $form.find(`input[name="${errorName}"]`);

                        if ($field.length) {
                            $field.parsley().addError('custom-error', {message: errors[errorName]});
                        }
                    }
                });
            });
        },

        getFormData() {
            return {
                token: this.token,
                email: this.email,
                password: this.password,
                password_confirmation: this.password_confirmation,
            }
        }
    }
});
