<template>
    <section id="subscribe" class="subscribe" :class="{ 'subscribe--new': newSubscribeStyle }">
        <form action="" method="post">
            <div class="container">
                <div class="row">
                    <h2>Exclusive deals</h2>
                    <span>Don’t miss out on amazing deals and discount codes! Sign up and we will send them to you e-mail!</span>

                    <transition name="fade" mode="out-in">
                        <div class="subscribe-form" v-if="!isSubscribed" key="subscribe">
                            <div class="email">
                                <input
                                    type="email"
                                    v-model="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    autocomplete="off"
                                    required
                                />
                            </div>
                            <button type="submit" class="btn-brand-primary">
                                Subscribe <i class="apr-arrow-right" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div v-else key="subscribed">
                            <div class="success">
                                You have been subscribed <i class="far fa-thumbs-up" aria-hidden="true"></i>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </form>
    </section>
</template>

<script>
export default {
    data: function () {
        return {
            email: null,
            isSubscribed: false,
            newSubscribeStyle: window.apr.newSubscribeStyle,
        }
    },

    mounted() {
        this.initSearchboxForm();
    },

    methods: {
        initSearchboxForm() {
            let self = this
            let requesting = false
            let $form = $(this.$el).find('form')

            $form
                .parsley()
                .on('form:submit', function () {
                    $form.find('input').each(function () {
                        $(this).parsley().removeError('custom-error')
                    })
                    return false
                })
                .on('form:success', function (formInstance) {
                    if (requesting) {
                        return false
                    }
                    requesting = false

                    self.$http.post('/newsletters/subscribe', { email: self.email }).then(
                        (response) => {
                            self.isSubscribed = true
                            dataLayer.push({
                                event: 'GaEvent',
                                EventCategory: 'Newsletter',
                                EventAction: 'subscribing',
                            })
                        },
                        (response) => {
                            let errors = response.body

                            for (var errorName in errors) {
                                let $field = $form.find(`input[name="${errorName}"]`)

                                if ($field.length) {
                                    $field.parsley().addError('custom-error', { message: errors[errorName] })
                                }
                            }

                            requesting = false
                        }
                    )

                    return false
                })
        },
    },
}
</script>
