<template>
    <slick-carousel ref="slick" class="base-slider" :options="slickOptions" @afterChange="handleAfterChange" :key="carouselKey">
        <slot />
    </slick-carousel>
</template>

<script>
import Slick from 'vue-slick';

export default {
    components: {
        'slick-carousel': Slick,
    },

    props: {
        carouselKey: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {
            slickOptions: {
                autoplay: false,
                autoplaySpeed: 1000,
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,
                arrows: true,
                dots: true,
                responsive: [
                    {
                        breakpoint: 1100,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                        },
                    },
                ],
            },
        };
    },

    methods: {
        handleAfterChange(event, slick, currentSlide) {
            const prev = this.$refs.slick.$el.querySelector('.slick-prev');
            const next = this.$refs.slick.$el.querySelector('.slick-next');
            if (prev) {
                prev.style.opacity = currentSlide === 0 ? '0' : '1';
                prev.style.pointerEvents = currentSlide === 0 ? 'none' : 'all';
            }
            if (next) {
                next.style.opacity = currentSlide === slick.$slides.length - 3 ? '0' : '1';
                next.style.pointerEvents = currentSlide === slick.$slides.length - 3 ? 'none' : 'all';
            }
        },
    },
};
</script>

<style lang="scss">
.base-slider {
    margin-left: -12px;
    margin-right: -12px;

    .slick-track {
        display: flex;
        align-items: stretch;
        padding: 12px 0;
    }
    .slick-slide {
        display: flex;
        align-items: stretch;
        height: auto;
        & > div {
            width: 100%;
            display: flex;
            align-items: stretch;
            padding: 12px;
        }
    }

    .slick-prev {
        opacity: 0;
        pointer-events: none;
    }

    .slick-prev,
    .slick-next {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
        z-index: 10;
        transition: opacity 0.3s;
        top: 185px;
         &.slick-disabled {
            opacity: 0.8;
            pointer-events: none;
        }
        &:before {
            content: '';
            width: 19px;
            height: 20px;
            display: block;
            opacity: 1;
        }
    }

     .slick-prev {
        left: -15px;
        &:before {
            margin-right: 4px;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='19' height='20' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.999999 10L4.48046 6.66667L6.93031 4.32039L11.4414 4.85949e-07L14.9219 3.33333L10.4277 7.63754L17 7.63754L17 12.3463L10.4277 12.3463L14.9219 16.6667L11.4414 20L6.93031 15.6796L4.48047 13.3333L0.999999 10Z' fill='%23B4E076'/%3E%3C/svg%3E%0A");
        }
    }
    .slick-next {
        right: -15px;
        &:before {
            margin-left: 2px;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='19' height='20' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 10L14.5195 13.3333L12.0697 15.6796L7.55861 20L4.07814 16.6667L8.57233 12.3625H2L2 7.65372L8.57233 7.65372L4.07814 3.33333L7.55861 0L12.0697 4.32039L14.5195 6.66667L18 10Z' fill='%23B4E076'/%3E%3C/svg%3E%0A");
        }
    }

    .slick-dots {
        position: relative;
        bottom: auto;
        display: flex;
        justify-content: center;
        li {
            width: 8px;
            height: 8px;
            button {
                padding: 0;
                background: #B4E076;
                border-radius: 50%;
                width: 8px;
                height: 8px;
                opacity: 0.4;
                &::before {
                    display: none;
                }
            }
        }
    }
    .slick-dots li.slick-active button {
        opacity: 1;
        transform: scale(1.25);
    }

    &.slick-dotted.slick-slider {
        margin-bottom: 0;
    }
}

.popular-lots .base-slider {
    .slick-prev,
    .slick-next {
        top: 140px;
    }
}
</style>
