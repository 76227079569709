<template>
    <div class="searchbox landing">
        <!-- TABS -->
        <div class="row tabs">
            <div class="tab parking-tab">
                <div class="heading">Most Convenient</div>
                <div class="button" :class="{ 'active': currentTab === 'parking' }" @click="setTab('parking')">
                    <div class="icon"><i class="apr-icon-car"></i></div>
                    {{ isCruiseport ? 'Cruise Port' : 'Airport' }} Parking Only
                </div>
            </div>
            <div class="tab hotel-tab" v-if="showBv">
                <div class="heading">Best Value</div>
                <div class="button" :class="{ 'active': currentTab == 'hotel' }" @click="setTab('hotel')">
                    <div class="icon"><i class="apr-icon-bed"></i> + <i class="apr-icon-car"></i></div>
                    Hotel & Parking Package
                </div>
            </div>
            <div class="tab shuttle-tab" v-if="showSf">
                <div class="heading">Most Affordable</div>
                <div class="button" :class="{ 'active': currentTab == 'shuttle' }" @click="setTab('shuttle')">
                    <div class="icon"><i class="apr-icon-bus-2-line"></i></div>
                    Shuttle Transport
                </div>
            </div>
        </div>

        <!-- FIELDS -->
        <form action="" method="post" autocomplete="off" ref="searchboxForm">
            <option-apr v-if="currentTab == 'parking'"></option-apr>
            <option-psf v-else-if="currentTab == 'hotel'"></option-psf>
            <option-sf v-else-if="currentTab == 'shuttle'"></option-sf>
            <option-placeholder v-else></option-placeholder>
        </form>
    </div>
</template>

<script>
    import SearchboxMixin from "./SearchboxMixin.js";
    import RequestMixin from "./Requests.js";
    import OptionAPR from "./options/OptionsAPR.vue";
    import OptionPSF from "./options/OptionsPSF.vue";
    import OptionSF from "./options/OptionsSF.vue";
    import OptionPlaceholder from "./options/OptionsPlaceholder";

    export default {
        mixins: [SearchboxMixin, RequestMixin],
        components: {'option-apr': OptionAPR, 'option-psf': OptionPSF, 'option-sf': OptionSF, OptionPlaceholder},
        data() {
            return {
                type: 'searchbox',
                date1: null,
                date2: null,
                currentUrl: ''
            }
        },

      created() {
        this.currentUrl = window.location.pathname;
      },

        mounted() {
            let self = this
            this.$root.$on('session-received', function (data) {
                if (data.searchbox) {
                    // use airport info from page (not from session)
                    if (_.get(self.query, 'airport.code')) {
                        data.searchbox.airport = _.get(self.query, 'airport');
                    }

                    // re-store session
                    self.$store.dispatch('updateSession', data.searchbox);

                    // Set Selected Option from session data if not APR
                    if (data.searchbox.package != 'APR') {
                        self.selectedOption = data.searchbox.package;
                    }

                    if (self.package) {
                        self.setPackage(self.package);
                    } else {
                        self.setPackage(self.query.package);
                    }

                } else {
                    if (self.package) {
                        self.setPackage(self.package)
                    } else {
                        self.setPackage('APR');
                    }
                }
            })
            this.initSearchboxForm()
        },
        watch: {
            'query.date1': {
                handler: function (val, oldVal) {
                    if (val) {
                        this.date1 = moment(val).format('ddd, MMM D')
                    }
                }
            },
            'query.date2': {
                handler: function (val, oldVal) {
                    if (val) {
                        this.date2 = moment(val).format('ddd, MMM D')
                    }
                }
            },
        },
        methods: {
            setTab(tab) {
                if (tab === 'parking') {
                    this.$store.dispatch('updatePackage', 'APR');
                    this.setPageHeading('apr')
                } else if (tab === 'shuttle') {
                    this.$store.dispatch('updatePackage', 'SF');
                    this.setPageHeading('SF')
                } else {
                    this.$store.dispatch('updatePackage', this.selectedOption);
                    this.setPageHeading('psf')
                }
                this.$store.dispatch('updateTab', tab);
            },

            setPageHeading(tab) {
                if (!this.h1) {
                    var h1 = document.querySelector('#hero .hero-heading h1');
                    var h2 = document.querySelector('#hero .hero-heading h2');
                    if (this.headings) {
                        h1.innerHTML = this.headings[tab]['h1'];
                        h2.innerHTML = this.headings[tab]['h2'];
                    }
                }
            },

            setPackage(pckg) {
                this.$store.dispatch('updatePackage', pckg)

                if (pckg === 'APR') {
                    this.$store.dispatch('updateTab', 'parking')

                } else if (pckg === 'SF') {
                    this.$store.dispatch('updateTab', 'shuttle')

                } else {
                    this.$store.dispatch('updateTab', 'hotel')
                }
            },
          isPageSpiritAirlines()
          {
            return this.currentUrl === '/spirit-airlines'
          }
        }
    };
</script>
