<template>
    <section v-if="showTabsComponent" class="guide-section">
        <div class="guide-section__container container">
            <h2 v-if="info.title" class="guide-section__title section-title">{{ info.title }}</h2>
            <div v-if="info.subtitle" class="guide-section__subtitle section-subtitle" v-html="info.subtitle"></div>
            <div class="guide-section__tabs">
                <div class="tabs">
                    <ul class="tabs__controls">
                        <li v-for="(item, index) in tabs" :key="item.title" class="tabs__control">
                            <button
                                class="tabs__control-btn"
                                :class="{ 'is-active': activeTab === index }"
                                @click="setActiveTab(index)"
                            >
                                {{ item.title }}
                            </button>
                        </li>
                    </ul>
                    <div class="tabs__content-wrapper" ref="tabs-content">
                        <div
                            v-for="(item, index) in info.tabs"
                            :key="item.title"
                            class="tabs__content"
                            :class="{ 'is-active': activeTab === index }"
                        >
                            <div class="tabs__content-text" v-html="item.content"></div>
                            <div class="tabs__content-image">
                                <div
                                    :style="{
                                        backgroundImage: `url(${item.image}), url(${storage_url}/img/misc/guide_tab_${index + 1}.png)`,
                                    }"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        info_type: {
            type: String,
            default: 'airport_guide',
        },
    },

    data() {
        return {
            info: apr[this.info_type],
            showTabsComponent: false,
            activeTab: 0,
            storage_url: apr.storage_url,
        };
    },

    computed: {
        tabs() {
            if (!this.info || !this.info.tabs) return [];
            return this.info.tabs.filter((tab) => tab.title && tab.content);
        },
    },

    mounted() {
        if (this.info && this.info.tabs && this.info.title) this.showTabsComponent = true;
    },

    methods: {
        setActiveTab(index) {
            this.activeTab = index;
        },
    },
};
</script>

<style lang="scss">
.guide-section {
    color: #212529;
    &__subtitle {
        margin-left: auto;
        margin-right: auto;
    }
}

.tabs {
    &__controls {
        display: flex;
        justify-content: center;
        gap: 40px;
        list-style-type: none;
        margin-bottom: 48px;
        padding: 0;
    }
    &__control-btn {
        position: relative;
        background: none;
        color: #343a40;
        font-family: 'Expressway', sans-serif;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1.813rem;
        padding: 10px;
        border: none;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background: #212529;
        }
        &.is-active {
            font-weight: 700;
            &:after {
                background: #34b4f5;
                height: 4px;
            }
        }
    }
    &__content {
        display: none;
        width: 100%;
        &.is-active {
            display: flex;
            align-items: center;
        }
        &-wrapper {
            position: relative;
        }
        &-text {
            flex-grow: 1;
            margin-right: 24px;
            text-align: left;
            font-size: 1.125rem;
            font-weight: 400;
            font-size: 1.563rem;
            p + h3,
            p + h4,
            p + h5,
            p + h6 {
                margin-top: 28px;
            }
            p:last-child {
                margin-bottom: 0;
            }
        }
        &-image {
            width: 454px;
            flex-shrink: 0;
            background: #fff;
            div {
                width: 100%;
                height: 454px;
                background-size: cover;
                background-position: center;
            }
        }
    }
}

@media (max-width: 1200px) {
    .tabs__content-image {
        width: 350px;
        div {
            height: 350px;
        }
    }
    .guide-section {
        margin-bottom: 96px;
    }
}

@media (max-width: 920px) {
    .tabs__content-image {
        display: none;
    }
    .guide-section {
        margin-bottom: 64px;
    }
}
</style>
