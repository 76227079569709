import Helpers from '../helpers'

export default {
    mixins: [Helpers],

    methods: {
        trackViewItemList() {
            console.log('here we are');
            console.log(this.search );
            let itemsList = [];
            let position = 1;

            // Available
            _.forEach(this.search, (item) => {
                let category = this.getLotCategory(item.provider);
                let price = item.rate != 'undefined' ? item.rate : 0.00;
                let taxesIncluded = !!item.taxes_included;
                let discount = item.baseRate
                    ? parseFloat(_.floor(item.baseRate - price, 2).toFixed(2)) // This is to get rid of repeating decimals
                    : 0;

                const product = {
                    'item_id': item.id ? item.id : item.hotel_id,
                    'item_name': item.name,
                    'coupon': '',
                    'currency': '',
                    'discount': discount,
                    'item_list_position': position,
                    'item_category': category,
                    'item_list_name': 'Search Results',
                    'price': price,
                    'salesTaxIncluded': taxesIncluded,
                    'feesIncluded': false,
                    'fees': 0,
                    'tax': 0,
                    'quantity': 1,
                };

                itemsList.push(product);
                position++;
            });

            // Soldout
            _.forEach(this.soldout, (item) => {
                let category = this.getLotCategory(item.provider);

                const product = {
                    'item_id': item.id ? item.id : item.hotel_id,
                    'item_name': item.name,
                    'coupon': '',
                    'currency': '',
                    'discount': 0,
                    'item_list_position': position,
                    'item_category': category,
                    'item_list_name': 'Search Results',
                    'price': 0.00,
                    'salesTaxIncluded': false,
                    'feesIncluded': false,
                    'fees': 0,
                    'tax': 0,
                    'quantity': 0,
                };

                itemsList.push(product);
                position++;
            });

            //dataLayer - view_item_list
            dataLayer.push({
                'event': 'view_item_list',
                'ecommerce': {
                    'items': itemsList
                }
            });
        },
    }
}