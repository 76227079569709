<template>
  <div class="w-100">
    <div class="container" v-show="searchEdit">
      <form ref="searchboxForm">
        <div class="row m-0">

          <!-- Flight Departure Airport -->
          <div class="item airport col-12 col-lg pl-0" :class="{ 'error': showErrors && !airport.code, 'cruiseport': isCruiseport }">
            <label>{{ isCruiseport ? 'Cruiseport' : 'Airport' }}</label>

            <algolia-typeahead
                id="searchbox-airport"
                name="searchbox-airport"
                ref="algoliaTypeahead"
                :airport="airport"
                class="direction-search-typeahead"
                :placeholder="isCruiseport ? 'Enter cruise port' : 'Enter airport'"
                v-on:typeahead-change="setAirport"
            ></algolia-typeahead>
          </div>

          <!-- Flight Direction -->
          <div class="item direction col-12 col-lg px-0 pl-lg-3" :class="{ 'error': showErrors && !direction }">
            <label>To or From</label>
            <div class="dropdown">
              <button class="btn dropdown-toggle" :class="{ 'null': !direction || direction === 'null' }" type="button" id="Direction" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span v-if="!direction">Going or coming from?</span>
                <span v-else-if="direction === 'fromAirport'">Coming from {{ airport.code }}</span>
                <span v-else-if="direction === 'fromAddress'">Going to {{ airport.code }}</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="FlightDirection">
                <button class="dropdown-item" type="button" @click.prevent="setDirection('fromAirport')">Coming from {{ airport.code }}</button>
                <button class="dropdown-item" type="button" @click.prevent="setDirection('fromAddress')">Going to {{ airport.code }}</button>
              </div>
            </div>
          </div>

          <!-- Flight Arrival Airport -->
          <div class="item address col-12 col-lg px-0 pl-lg-3" :class="{ 'error': showErrors && !address }">
            <algolia-google-typeahead-s-f class="w-100" title="Address" variable="address" placeholder="Enter Address" required></algolia-google-typeahead-s-f>
          </div>

          <!-- Submit -->
          <div class="item col-12 col-lg px-0">
            <div class="submit px-0 pl-lg-3">
              <button type="submit" class="btn-brand-primary" @click.prevent="search">
                Search Directions
                <i class="apr-arrow-right" aria-hidden="true"></i>
                <span v-show="working" class="btn-brand-primary__loading-container">
                  <span class="btn-brand-primary__loading"></span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <section id="directions-view" v-show="!searchEdit">
      <div class="container px-5">
        <h1>Your directions {{ this.direction === 'fromAirport' ? 'from' : 'to' }} {{ this.airport.name }}</h1>
        <h4>
          <div class="d-flex align-items-center">{{ this.direction === 'fromAirport' ? 'To' : 'From' }}
            <span class="font-weight-lighter ml-3" style="font-size: 1.125rem">{{ this.address.name}}</span></div>
        </h4>
        <a href="#" @click.prevent="searchEdit = true">Edit Search</a>
      </div>
    </section>
  </div>
</template>

<script>
	import AlgoliaTypeahead from '../components/Searchbox/components/AlgoliaTypeahead';
	import AlgoliaGoogleTypeaheadSF from '../components/Searchbox/components/AlgoliaGoogleTypeaheadSF';
	import * as VueGoogleMaps from 'vue2-google-maps'

	export default {
		components: {AlgoliaGoogleTypeaheadSF, AlgoliaTypeahead},
		data() {
			return {
				working: false,
				showErrors: false,
				searchEdit: true,

				defaultAirport: _.get(window, 'apr.airport'),
				airport: {
					name: null,
					code: null,
					type: null,
				},
				content: apr ? apr.content : null,
				direction: null,
				address: '',

				isCruiseport: false,
			}
		},
		mounted() {
			if (_.get(window, 'apr.airport')) {
				this.airport = _.get(window, 'apr.airport');

				this.$nextTick(() => {
					this.$refs.algoliaTypeahead.preSelectAirport();
				})
			}

			$('#placeholderHeaders').remove();

			setTimeout(() => {
				Vue.use(VueGoogleMaps, {load: window.googleMapsConfig})
			}, 500);
		},

		watch: {
			'airport.type': function (airportType) {
				airportType = _.toLower(airportType);

				// update searchbox on airport type change
				if (_.indexOf(['airport', 'cruiseport'], airportType) !== -1) { // airport & cruiseport
					if (airportType === 'cruiseport') {
						this.isCruiseport = true
					} else {
						this.isCruiseport = false
					}
				}
			},
      'searchEdit': function(val) {
				if(!val){
					$('#directions-search').addClass('not-editing')
        }else{
			    $('#directions-search').removeClass('not-editing')
        }
      }
		},

		methods: {
			setAirport(airport) {
				this.airport = airport;
			},

			setDirection(direction) {
				if (direction == 'fromAirport') {
					dataLayer.push({
						'event': 'GaEvent',
						'EventCategory': 'Directions',
						'EventAction': 'coming from ' + this.airport.code,
						'EventLabel': this.address.formatted_address
					})
				} else {
					dataLayer.push({
						'event': 'GaEvent',
						'EventCategory': 'Directions',
						'EventAction': 'going to ' + this.airport.code,
						'EventLabel': this.address.formatted_address
					})
				}
				this.direction = direction
			},

			search() {
				if (this.airport.code == '') {
					$('#directions-search .item.airport').addClass('error')
				} else {
					$('#directions-search .item.airport').removeClass('error')
				}

				if (this.direction == null) {
					$('#directions-search .item.direction').addClass('error')
				} else {
					$('#directions-search .item.direction').removeClass('error')
				}

				if (this.address == '') {
					$('#directions-search .item.address').addClass('error')
				} else {
					$('#directions-search .item.address').removeClass('error')
				}

				if (this.airport.code == '' || this.direction == null || this.address == '') {
					return
				}

				const origin = (this.direction === 'fromAirport' ? this.airport.name : this.address.name).replace(/ /g, "+");
				const destination = (this.direction === 'fromAirport' ? this.address.name : this.airport.name).replace(/ /g, "+");

				this.working = true;
				this.$http.post('/directions', {
					'origin': origin,
					'destination': destination
				}).then((response) => {
					this.working = false;
					this.searchEdit = false;
					this.working = false;
					this.$parent.results = response.body.data.routes[0].legs[0].steps;
					dataLayer.push({
						'event': 'GaEvent',
						'EventCategory': 'Airport Directions',
						'EventAction': 'Searched directions',
						'EventLabel': this.airport.code
					})
				});
			}
		}
	}
</script>
