import Stars from "./../../components/stars";
import AlertBar from "./../../components/AlertBar.vue"
import SearchFilter from "./../../components/search-filter";
import * as VueGoogleMaps from 'vue2-google-maps';
import Helpers from "../../../../shared/helpers";

if (apr.app_page_name === 'psf_search_results') {
    Vue.use(VueGoogleMaps, {load: window.googleMapsConfig})
}

Vue.component('page-psf-search', {

    components: {
        Stars,
		'alert-bar': AlertBar,
        'gmap': VueGoogleMaps.Map,
        'gmarker': VueGoogleMaps.Marker,
        'ginfoWindow': VueGoogleMaps.InfoWindow
    },

    mixins: [SearchFilter, Helpers],

    data: function () {
        return {
            pageLoaded: false,
            airport: {},
            dates: {},
            search: [],

            mapObj: {
                center: {lat: 0, lng: 0},
                mapTypeId: "roadmap",
                zoom: 10,
				minZoom: 4,
                options: {
                    styles: [
                        {featureType: "poi", elementType: "labels", stylers: [{"visibility": "off"}]}
                    ],
                    controlSize: 32,
                },
            },
            markers: [],
            infoWindowObj: {
                opened: false,
                position: {lat: 0, lng: 0},
                options: {},
                content: ''
            },

            soldout: '',
            filter_list: [],
            filter_options: [],
            sort_option: 'popular-asc',
            apr_results_link: apr.apr_results_link,

            reservationProcessError: _.get(window, 'apr.exp_error'),
            reservationProcessStatus: _.get(window, 'apr.exp_status'),
            expediaHotelName: _.get(window, 'apr.exp_hn'),

            error_dialog: {
                show: false,
                bold: `${apr.soldout} is <span class="highlight">sold out.</span>`,
                normal: 'Hotels are selling out quick for your travel dates.<br/>Take a look at some other great options',
                excerpt: 'Here are some other great options.'
            },
        }
    },

    mounted() {
        // filter functions
        this.detectHideFilter();
        this.selectSortByOption('PSF');

        this.initStickyMap();

        this.airport = apr.search_psf.airport
        this.dates = {
            checkindate: apr.search_psf.search_details.dates.parking_start,
            checkoutdate: apr.search_psf.search_details.dates.parking_end,
        }

        this.filter_list = null

        let map = this.$refs.searchResultsMap

        this.setMapCenter()
        setTimeout(() => {
            this.setMapOptions()
            this.filterProducts()

            this.pageLoaded = true;
            this.mapFitBounds(map)
        }, 500);

        if (apr.soldout) {
            this.error_dialog.show = true
        }

        // for back button functionality
        window.addEventListener("popstate", function (event) {
            if (event.state && event.state.action === "product") {
                window.location.reload();
            }
        }, false);
    },

    methods: {
        showEmailModal(){
            $('#Email_Modal').modal('show')
            dataLayer.push({
                'event': 'GaEvent',
                'EventCategory': 'PSF Saved Search',
                'EventAction': 'Modal - Opened',
                'EventLabel': ''
            })
        },

        sendSearchResults(){
            let email_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (email_regex.test(this.email)) {
                this.emailSending = true
                this.$http.post(apr.saved_search_url, {
                    'email_address': this.email,
                    'token': apr.search_psf.search.data.token,
                    'optin': this.emailOptIn
                }).then((response) => {
                    this.emailErrorMessage = null
                    this.email = null
                    this.emailSending = false
                    dataLayer.push({
                        'event': 'GaEvent',
                        'EventCategory': 'PSF Saved Search',
                        'EventAction': 'Modal - Submitted',
                        'EventLabel': ''
                    })
                    $('#Email_Modal').modal('hide')
                    swal({
                        title: "Email Sent!",
                        type: "success",
                        timer: 2000,
                        showConfirmButton: false
                    });
                }, (response) => {
                    this.emailErrorMessage = 'Please enter correct e-mail address'
                })
            } else {
                this.emailErrorMessage = 'Please enter correct e-mail address'
            }
        },

        hideEmailModal() {
            this.emailErrorMessage = null
            this.email = null
            dataLayer.push({
                'event': 'GaEvent',
                'EventCategory': 'PSF Saved Search',
                'EventAction': 'Modal - Closed',
                'EventLabel': ''
            })
            $('#Email_Modal').modal('hide')
        },

        setMapCenter(){
            this.mapObj.center = {
                lat: Number(this.airport.geo.latitude),
                lng: Number(this.airport.geo.longitude)
            }
        },

        setMapOptions() {
            let options = {
                options: {
                    scrollwheel: false,
                    mapTypeControl: true,
                    mapTypeControlOptions: {
                        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                        position: google.maps.ControlPosition.TOP_LEFT
                    },
                    zoomControl: true,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.LEFT_TOP
                    },
                    scaleControl: true,
                    streetViewControl: true,
                    streetViewControlOptions: {
                        position: google.maps.ControlPosition.LEFT_TOP
                    }
                }
            }

            $.extend(this.mapObj, options)
        },

        filterProducts() {
            let self = this

            if (this.filter_options.length > 0) {
                this.search = _.filter(apr.search_psf.results, function (o) {
                    if (o.provider == 'APR') {
                        let result = false

                        for (var i = 0, len = self.filter_options.length; i < len; i++) {
                            if (o.options.indexOf(self.filter_options[i]) > -1) {
                                result = true
                            }
                        }
                        return result
                    }

                    if (o.provider == 'PSF' && self.filter_options.indexOf('Hotel') > -1) {
                        return true
                    }
                })

            } else {
                this.search = apr.search_psf.hotels
            }

            if ( ! this.search) {
                this.trackEvent('No products available')
            }

            this.sortList()

            this.getPins(this.search)

            if(this.isTouchDevice() === false) {
                setTimeout(() => {
                    $('.psf i[data-toggle="tooltip"]').tooltip()
                }, 100)
            }
        },

        isTouchDevice(){
            return true == ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch);
        },

        sortList() {
            let [type, direction] = this.sort_option.split("-")

            if (type != 'popular') {
                if (type == 'rating') {
                    this.search = _.orderBy(this.search, function (o) {
                        if (o.provider == 'APR') {
                            return o.ratings.rating
                        }
                        return o.ratings.average_rating
                    }, direction)

                } else if (type == 'distance') {
                    this.search = _.orderBy(this.search, [type], direction)

                } else if (type == 'rate') {
                    this.search = _.orderBy(this.search, [type], direction)
                }
            }
        },

        getPins(lots) {
            this.markers = []
            this.getAirportMarker()

            for (let lot of Array.from(lots)) {
                let marker = this.getMarkerProperties(lot);
                this.markers.push(marker)
            }
        },

        getAirportMarker() {
            this.markers = [{
                type: this.airport.type === 'CruisePort' ? 'cruise' : 'airport',
                position: {lat: Number(this.airport.geo.latitude), lng: Number(this.airport.geo.longitude)},
                icon: {
                    url: apr.storage_url + '/img/icons/' + (this.airport.type === 'CruisePort' ? 'cruise-pin-new-grey.png' : 'airport-pin-new-black.png'),
                    scaledSize: new google.maps.Size(45, 36),
                    anchor: new google.maps.Point(23, 36)
                },
                animation: google.maps.Animation.FADE,
                draggable: false
            }]
        },

        mapFitBounds(map) {
            let bounds = new google.maps.LatLngBounds();

            this.markers.forEach((marker) => {
                let point = new google.maps.LatLng(marker.position.lat, marker.position.lng);
                bounds.extend(point);
            })

		    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
		       var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
		       var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
		       bounds.extend(extendPoint1);
		       bounds.extend(extendPoint2);
		   }

            map.fitBounds(bounds)
        },

        isDestinationMarker(marker) {
            return (_.indexOf(['airport', 'cruiseport'], marker.type) !== -1)
        },

        mouseOverMarker(hover, marker) {
            if (this.isDestinationMarker(marker)) {
                return
            }

            // on mouse out - dont make it inactive
            if (marker.active) {
                return
            }

            marker.icon = this.getMarkerIcon(marker.provider, hover)
            marker.zIndex = hover ? 999999 : marker.zIndexOriginal
        },

        markerStatusChange(marker) {
            let isActive = marker.active ? true : false

            marker.icon = this.getMarkerIcon(marker.provider, isActive)
            marker.zIndex = isActive ? 999998 : marker.zIndexOriginal
        },

        highlightMarker(product) {
            let marker = {
                position: {lat: Number(product.geo.latitude), lng: Number(product.geo.longitude)},
                icon: this.getMarkerIcon(product.provider, true),
                label: this.getMarkerLabelProperties(product.provider, product),
                zIndex: 999999,
                optimized: false,
                hovered: true,
                provider: product.provider,
            }

            this.markers.push(marker)
        },

        unHighlightMarker() {
            this.markers = this.markers.filter((marker) => marker.hovered === undefined);
        },

        getMarkerIcon(provider, hover = false) {
            return {
                url: this.getMarkerIconPath(provider, hover),
                scaledSize: new google.maps.Size(45, 36),
                anchor: new google.maps.Point(23, 36),
                labelOrigin: new google.maps.Point(22, 14)
            }
        },

        getMarkerIconPath(provider, hover) {
            if (provider === 'APR') {
                return apr.storage_url + (hover ? '/img/icons/lot-new-hover-pin.png' : '/img/icons/lot-new-pin.png')
            }

            return apr.storage_url + (hover ? '/img/icons/hotel-product-pin-new-hover.png' : '/img/icons/hotel-product-pin-new.png')
        },

        getMarkerLabelProperties(provider, lot) {
            if (provider === 'APR') {
                return {
                    text: "$" + Math.round(lot.rate),
                    color: 'white',
                    fontSize: '0.75rem'
                }
            }

            return {
                text: " ",
                color: '',
                fontSize: ''
            }
        },

        openInfoWindow(marker) {
            let self = this
            this.infoWindowObj.opened = false

            if (marker.geo === undefined) {
                this.infoWindowObj.opened = false
                return false
            }

            dataLayer.push({
                'event': 'GaEvent',
                'EventCategory': 'Map',
                'EventAction': 'info window open',
                'EventLabel': ''
            })

            setTimeout(() => {
                this.infoWindowObj.opened = true
                this.infoWindowObj.position = {lat: Number(marker.geo.latitude), lng: Number(marker.geo.longitude)}
                this.infoWindowObj.options = {
                    pixelOffset: new google.maps.Size(0, -35),
                    content: this.getInfoWindowContent(marker)
                }
                this.infoWindowObj.content = this.getInfoWindowContent(marker)

                // reset markers
                self.makeAllMarkersInactive()

                // make current as active
                if (!self.isDestinationMarker(marker)) {
                    marker.active = true
                    self.markerStatusChange(marker)
                }
            }, 100)
        },

        getMarkerProperties(lot) {
            let props = {
                position: {lat: Number(lot.geo.latitude), lng: Number(lot.geo.longitude)},
                animation: google.maps.Animation.FADE,
                draggable: false,
                flat: true,

                provider: lot.provider,
                name: lot.name,
                media: {logo: lot.media.logo},
                geo: {
                    latitude: lot.geo.latitude,
                    longitude: lot.geo.longitude
                },
                rate_str: lot.rate_str,
                distance: lot.distance,
            };

            let props2 = {}
            if (lot.provider === 'APR') {
                props2 = {
                    icon: this.getMarkerIcon(lot.provider),
                    label: this.getMarkerLabelProperties(lot.provider, lot),
                    zIndex: lot.id,
                    zIndexOriginal: lot.id,
                    ratings: {
                        rating: lot.ratings.rating,
                        rating_count: lot.ratings.rating_count
                    },
                    content: {slug: lot.content.slug},
                    id: lot.id,
                }

            } else if (lot.provider === 'PSF') {
                props2 = {
                    icon: this.getMarkerIcon(lot.provider),
                    label: this.getMarkerLabelProperties(lot.provider, lot),
                    zIndex: lot.hotel_id,
                    zIndexOriginal: lot.hotel_id,
                    ratings: {
                        average_rating: lot.ratings.average_rating,
                        reviews_quantity: lot.ratings.reviews_quantity
                    },
                    content: {slug: this.filters.hotelUrl(lot.slug, 'apr')},
                    id: lot.hotel_id,
                }
            }

            $.extend(props, props2);
            // console.log(props)
            return props;
        },

        clickedMap() {
            this.infoWindowObj.opened = false
            this.makeAllMarkersInactive()
        },

        makeAllMarkersInactive() {
            let self = this

            _.map(self.markers, function (m) {
                if (m.active) {
                    m.active = false
                    self.markerStatusChange(m)
                }
                return m
            });
        },

        getInfoWindowContent(marker) {
            let html = `
                    <div class="row map-marker">
                        `;

            if (marker.provider == 'APR') {
                html += `
                    <div class="col-3 col-1">
                        <a href="/${marker.content.slug}" target="_blank">
                            <img src="${marker.media.logo}" alt="${marker.name}" width="100%" />
                        </a>
                    </div>
                    <div class="col-9 col-2">
                        <h3><a href="/${marker.content.slug}" target="_blank">${marker.name}</a></h3>
                        <div class="ratings py-2">
                            ${this.filters.drawStarsWithText(marker.ratings.rating, marker.ratings.rating_count)}
                        </div>
                        <div class="distance"><i class="apr-icon-plane-line"></i> ${marker.distance} miles from ${this.airport.code} airport</div>
                    </div>
                    <a href="/${marker.content.slug}" class="btn btn-red btn-wide mt-2" target="_blank">
                        Park Here
                    </a>`;

            } else {
                html += `
                    <div class="col-3 col-1">
                        <a href="${marker.content.slug}" target="_blank">
                            <img src="${marker.media.logo}" alt="${marker.name}" width="100%" />
                        </a>
                    </div>
                    <div class="col-9 col-2">
                        <h3><a href="${marker.content.slug}" target="_blank">${marker.name}</a></h3>
                        <div class="ratings">
                            ${this.filters.drawStarsWithText(marker.ratings.average_rating, marker.ratings.reviews_quantity)}
                        </div>
                        <div class="distance"><i class="apr-icon-plane-line"></i> ${marker.distance} miles from ${this.airport.code} airport</div>
                    </div>
                    <a href="${marker.content.slug}" class="btn btn-green btn-wide" target="_blank">
                        Sleep & Park Here ${(marker.rate_str ? ' | <small>From</small> ' + this.filters.priceRemoveZeros(marker.rate_str)  : '')}
                    </a>`;
            }

            html += '</div>';

            return html;
        },

        initStickyMap: function () {
            var map = $('#map_container');

            $(window).scroll(function (event) {
                setInterval(() => {
                    let st = $(window).scrollTop();
                    let heightToScroll = 257 - ($("#search_results .alert").length === 1 ? 0 : 32);

                    if (st > heightToScroll) {
                        map.addClass('sticky-map');
                    } else {
                        map.removeClass('sticky-map');
                        let height = window.innerHeight - (heightToScroll - $(window).scrollTop())
                        $("#map_container").css('height', height)
                    }
                }, 20);
            });
        },

        trackEvent(name) {
            dataLayer.push({
                'event': 'GaEvent',
                'EventCategory': apr.event_category,
                'EventAction': name,
                'EventLabel': ''
            });
        },

        ecommercePsfTracking(hotel, index) {
            //dataLayer - productClick
            let providerName = _.get(hotel, 'provider');
            let category = this.getLotCategory(providerName);
            let variant = typeof(hotel.available) != 'undefined' && hotel.available === true
                ? "available"
                : "sold out";

            dataLayer.push({
                'event': 'productClick',
                'ecommerce': {
                    'click': {
                        'actionField': {'list': 'PSF Search Results'},
                        'products': [{
                            'name': `PSF ${this.airport.code} ${hotel.name}`,
                            'id': `PSF ${this.airport.code}`,
                            'price': hotel.rate,
                            'brand': '',
                            'category': category,
                            'variant': variant,
                            'position': index
                        }]
                    }
                }
            })
        },
    }
});
