<template>
    <input type="checkbox"/>
</template>

<script>
    import merge from 'merge'

    if (!jQuery().bootstrapToggle) {
        require('bootstrap-toggle')
    }

    const defaults = {}

    export default {
        defaults,
        props: {
            value: Number | Boolean,
            options: {
                type: Object,
                default: () => ({})
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {updating: false};
        },
        computed: {
            $$el() {
                return jQuery(this.$el)
            }
        },
        watch: {
            value(newValue) {
                if (this.updating) {
                    return;
                }

                this.$$el.bootstrapToggle(newValue ? 'on' : 'off');
            },
            disabled(newValue) {
                this.$$el.bootstrapToggle(newValue ? 'disable' : 'enable');
            }
        },
        mounted() {
            let self = this
            if (this.value) {
                this.$el.checked = true
            }
            this.$$el.bootstrapToggle(merge.recursive(true, defaults, this.options))
            this.$$el.change(() => {
                this.updating = true;
                this.$emit('input', this.$$el.prop('checked'));
                this.$nextTick(() => this.updating = false);
            })

            $(document).on('click.bs.toggle', 'div[data-toggle^=toggle]', function(e) {
                self.$emit('click', self.$$el.prop('checked'))
            })
        },
        beforeDestroy() {
            this.$$el.bootstrapToggle('destroy')
            this.$$el.off('change')
        }
    }
</script>

<style lang="scss">
    label.checkbox .toggle,
    label.checkbox.inline .toggle {
        margin-left: -20px;
        margin-right: 5px;
    }
    .toggle {
        position: relative;
        height: 50px !important;
        width: 100% !important;
        overflow: hidden;
    }
    .toggle.btn {
        border: 1px solid rgba(0, 0, 0, 0.15);
    }
    .toggle input[type="checkbox"] {
        display: none;
    }
    .toggle-group {
        position: absolute;
        width: 200%;
        top: 0;
        bottom: 0;
        left: 0;
        transition: left 0.35s;
        -webkit-transition: left 0.35s;
        -moz-user-select: none;
        -webkit-user-select: none;
    }
    .toggle.off .toggle-group {
        left: -100%;
    }
    .toggle-on {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 50%;
        display: flex !important;
        align-items: center !important;
        justify-content: center;
        margin: 0;
        border: 0;
        border-radius: 0;
        font-size: 1rem !important;
        box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
        cursor: pointer;
    }
    .toggle-off {
        position: absolute;
        top: -1px;
        bottom: 0;
        left: 50%;
        right: -1px;
        display: flex !important;
        align-items: center !important;
        justify-content: center;
        margin: 0;
        border: 0;
        border-radius: 0;
        color: #8f8f8f;
        font-size: 1rem !important;
        box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
        cursor: pointer;
		&.btn{
			background-color: #eff4f9;
			font-weight: 500;
			color: #5A5E6B;
		}
    }
    .toggle-handle {
        position: relative;
        margin: 0 auto;
        padding-top: 0px;
        padding-bottom: 0px;
        height: 100%;
        width: 0px;
        background: white;
        border: 1px solid rgba(0, 0, 0, 0.15) !important;
        cursor: pointer;
    }
    .toggle-handle.btn-mini {
        top: -1px;
    }
    .toggle.btn { min-width: 30px; max-width: 150px; }
    .toggle-on.btn { padding-right: 18px; }
    .toggle-off.btn { padding-left: 18px; }

    .toggle.btn-large { min-width: 40px; }
    .toggle-on.btn-large { padding-right: 35px; }
    .toggle-off.btn-large { padding-left: 35px; }

    .toggle.btn-small { min-width: 25px; }
    .toggle-on.btn-small { padding-right: 20px; }
    .toggle-off.btn-small { padding-left: 20px; }

    .toggle.btn-mini { min-width: 20px; }
    .toggle-on.btn-mini { padding-right: 12px; }
    .toggle-off.btn-mini { padding-left: 12px; }
</style>
