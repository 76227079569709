<template>
    <gmap-map
            :center="{lat: 41.85, lng: -87.65}"
            :zoom="zoom"
            ref="map"
    ></gmap-map>
</template>

<script>
    import * as VueGoogleMaps from 'vue2-google-maps'

    Vue.use(VueGoogleMaps, {load: window.googleMapsConfig});

    export default {
        props: {
            zoom: {
                type: Number,
                default: 7
            },
            origin: {
                type: String,
                required: true
            },
            destination: {
                type: String,
                required: true
            },
        },

        data: function () {
            return {
                directionsService: null,
                directionsDisplay: null,
            }
        },

        mounted() {
            VueGoogleMaps.loaded.then(() => {
                this.$refs.map.$mapCreated.then(() => {
                    this.initialize();
                });
            });
        },

        methods: {
            initialize() {
                this.directionsService = new google.maps.DirectionsService;
                this.directionsDisplay = new google.maps.DirectionsRenderer;
                this.directionsDisplay.setMap(this.$refs.map.$mapObject);

                this.navigate(this.origin, this.destination);
            },

            navigate(origin, destination) {
                this.directionsService.route({
                    origin: origin,
                    destination: destination,
                    travelMode: 'DRIVING'
                }, (response, status) => {
                    if (status === 'OK') {
                        this.directionsDisplay.setDirections(response);
                    } else {
                        console.log('Directions request failed due to ' + status)
                    }
                })
            }
        }
    }
</script>
